import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Typography } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext"
import ItemApprovalTable from '../../Components/VendorItems/ItemApprovalTable';
import AdminItemApprovalTable from '../../Components/VendorItems/AdminItemApprovalTable';

const VendorItemsForApproval = function(){
    const {user} = React.useContext(UserContext);

    return user ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Vendor Items For Approval
            </Typography>
            { (user.permissions.includes('admin') || user.permissions.includes('staff')) ? <AdminItemApprovalTable/>:<ItemApprovalTable/> }
        </Page>
        
    )
    : <Navigate to="/signin" />;
}

export default VendorItemsForApproval;
export const routeData = new RouteData({
    path:"/vendoritems/approval",
    element:<VendorItemsForApproval />,
},{
    name:"For Approval",
    icon:<CheckCircle />,
    isVisible:(user)=>Boolean(user),
});