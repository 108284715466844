import React from "react";

import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FullTable from "../FullTable";

const emptyJSON = {};
const defaultSearchOptions = ['Customer','Item Name', 'Note'];

const OrderTable = function({dataPath, urlSearchParams=emptyJSON, columns, AddOrderModal, addOrderModalProps=emptyJSON, searchOptions=defaultSearchOptions, refresh:refreshProp = 0}){
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(refreshProp);

    const didMount = React.useRef(false);
    React.useEffect(()=>{
        if(didMount.current){
            setRefresh(n=>n+1);
        }else{
            didMount.current = true;
        }
    },[refreshProp]);

    const mFullTable = React.useMemo(()=>{
        return (
            <FullTable
                dataPath={dataPath}
                urlSearchParams={urlSearchParams}
                columns={columns}
                leftHeading={AddOrderModal ? 
                    <Button 
                        color="primary" 
                        onClick={()=>setAddModalOpen(true)} 
                        startIcon={<AddCircleIcon />}
                        variant='contained'
                    >
                        Add Item Order
                    </Button>
                : null}
                searchOptions={searchOptions}
                refresh={refresh}
            />
        )
    },[dataPath, urlSearchParams, columns, AddOrderModal, refresh, searchOptions])

    return (
        <>
            {AddOrderModal ? 
                <AddOrderModal
                    modalOpen={addModalOpen}
                    onCloseModal={()=>setAddModalOpen(false)}
                    onSubmit={()=>setRefresh(n=>n+1)}
                    {...addOrderModalProps}
                />
            : null }
            {mFullTable}
        </>
    );
}

export default OrderTable;