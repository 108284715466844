import React from 'react';
import RouteData from '../RouteData';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import { Navigate } from 'react-router-dom';

const CashOutRequests = function(){
    return <Navigate to="/coreqs/approval" replace={true}/>;
}

export default CashOutRequests;
export const routeData = new RouteData({
    path:"/coreqs",
    element:<CashOutRequests />,
},{
    name:"Cashout Requests",
    icon:<RequestPageIcon />,
    isVisible:(user)=>user && !user.permissions.includes('staff'),
});