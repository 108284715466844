import React from "react";
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { FetchersContext } from "../../Contexts/FetchersContext";
import FormattedTable from "../FormattedTable";

const columns = [
    {
        id:"refId",
        label: "Referral ID",
        minWidth:60,
    },{
        id:'username',
        label:'Username',
        minWidth:60
    }
];

const getActionColumn = (user, data)=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:120,
        width:120,
        actions:[]
    };
    col.actions.push({
        title:'Remove',
        fn:(value)=>{
            data.openDeleteRefIDModal(value);
        },
        icon:<DeleteIcon fontSize="inherit"/>,
        isHidden:(value)=>Boolean(value.username)
    });
    return col;
}


const RefIDTable = ({display, refresh:refreshProp})=>{
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [tableData, setTableData] = React.useState({
        columns:[],
        data:[],
        count:0
    });
    const [pagination, setPagination] = React.useState({
        page:0,
        rowsPerPage:10,
    });
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);

    const [deleteRefID, setDeleteRefID] = React.useState({
        id:''
    });

    const openDeleteRefIDModal = (refID)=>{
        setDeleteRefID({
            ...refID
        });
    }

    React.useEffect(()=>{
        setLoading(true);
        fetchAuthed({
            path:'/api/users/refid?'+ new URLSearchParams({limit:pagination.rowsPerPage,offset:pagination.rowsPerPage*pagination.page}),
        },{
            succ:(data)=>{
                console.log(data);
                setTableData({
                    columns:[...columns, getActionColumn(null,{openDeleteRefIDModal})],
                    data:data.result,
                    count:data.count
                });
            },
            final:()=>{
                setLoading(false);
            } 
        });
    },[fetchAuthed, pagination, refresh, refreshProp]);

    const mFormattedTable = React.useMemo(()=>(
        <FormattedTable 
            tableData={tableData} 
            pagination={pagination} 
            onChangePagination={(p)=>setPagination(p)} 
            loading={loading}
            getRowId={(row)=>row.refId}
        />
    ),[loading, tableData, pagination]);

    return (
        <Box sx={{display:display?undefined:'none'}}>
            <DeleteRefIDModal
                refID={deleteRefID}
                onDelete={()=>setRefresh(n=>n+1)}
            />
            {mFormattedTable}
        </Box>
    );
}

const DeleteRefIDModal = ({refID, onDelete})=>{
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const [open, setOpen] = React.useState(false);

    const handleSubmit = (e)=>{
        e.preventDefault();
        fetchCSRF({
            succ:(csrf)=>{
                fetchAuthed({
                    path:'/api/users/refid/'+refID.id,
                    method:'delete',
                    headers:{
                        'x-csrf-token':csrf,
                    },
                },{
                    succ:()=>{
                        onDelete();
                        setOpen(false);
                    },
                    succMessage:'Successfully deleted user'
                })
            }
        });
    }

    React.useEffect(()=>{
        if(refID.id) setOpen(true);
    },[refID]);

    return(
        <Dialog
            fullWidth maxWidth='xs'
            open={open}
            onClose={()=>setOpen(false)}
            component='form'
            onSubmit={handleSubmit}
        >
            <DialogTitle>
                Delete Referral ID?
            </DialogTitle>
            <DialogContent>
                {refID.refID}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Cancel</Button>
                <Button type='submit'>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

export default RefIDTable;