import React from 'react';
import ResponsiveDrawer from '../../Components/Drawer';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { UserContext } from '../../Contexts/UserContext';
import { FetchersContext } from '../../Contexts/FetchersContext';
import { useLoaderData } from 'react-router-dom';
import { FetchersContainer } from '../../Components/FetchersContainer';
import SocketContainer from '../../Components/SocketContainer';
import { SocketsContext } from '../../Contexts/SocketsContext';

const theme = createTheme({
    palette:{
        primary:{
            main:'#B18A62',
        },
        secondary:{
            main:'#D0C360',
        },
    }
});

function Root(props){
    const initialUser = useLoaderData();
    const [user, setUser] = React.useState(initialUser);
    const [fetchers, setFetchers] = React.useState({
        fetchCSRF:()=>{},
        fetchAuthed:()=>{}
    });
    const [sockets, setSockets] = React.useState({
        socket:null,
        adminSocket:null
    });

    React.useEffect(()=>{
        sessionStorage.setItem('user',JSON.stringify(user));
        console.log(user);
    },[user]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <UserContext.Provider value={{user,setUser}}>
                <FetchersContext.Provider value={fetchers}>
                    <SocketsContext.Provider value={sockets}>
                        <SocketContainer setSockets={setSockets}/>
                        <FetchersContainer setFetchers={setFetchers}/>
                        <ResponsiveDrawer 
                            pathGroups = {props.pathGroups}
                            title="Inbox Baguio"
                        />
                    </SocketsContext.Provider>
                </FetchersContext.Provider>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default Root;