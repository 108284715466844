import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Typography } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext"
import CoreqsApprovedTable from '../../Components/Cashout Requests/CoreqsApprovedTable';
import AdminCoreqsApprovedTable from '../../Components/Cashout Requests/AdminCoreqsApprovedTable';

const CashOutRequestApproved = function(){
    const {user} = React.useContext(UserContext);

    return user ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Cashout Request Approved
            </Typography>
            { user.permissions.includes('admin') ? <AdminCoreqsApprovedTable/>:<CoreqsApprovedTable/> }
        </Page>
        
    )
    : <Navigate to="/signin" />;
}

export default CashOutRequestApproved;
export const routeData = new RouteData({
    path:"/coreqs/approved",
    element:<CashOutRequestApproved />,
},{
    name:"Approved",
    icon:<ListAltIcon />,
    isVisible:(user)=>Boolean(user) && !user.permissions.includes('staff')
});