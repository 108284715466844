import React from 'react';

import { Typography, Box, Stack, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, alpha} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import SubmitModal from '../../Components/SubmitModal';
import ReceiptModal from '../Sales/ReceiptModal';

const CheckOutButton = function({onCheckOut, itemQuantities:itemQuantitiesInput, disabled}){
    const [itemQuantities, setItemQuantities] = React.useState([]);
    const clickHandler = (e)=>{
        e.preventDefault();
        setItemQuantities([
            ...itemQuantitiesInput
        ]);
    }
    return (
        <>
            <Button variant='contained' disabled={disabled || itemQuantitiesInput.length<1} onClick={clickHandler} >Check Out</Button>
            <ReceiptFormModal itemQuantities={itemQuantities} onSubmit={onCheckOut}/>
        </>
    )
}

const defaultFormData = {
    items:[],
    cashReceived:'',
    electronicPaymentReceived:0,
    confirmationNumber: '',
    changeGiven:''
};
const numberFormat = new Intl.NumberFormat('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
const ReceiptFormModal = function({onSubmit, itemQuantities}){
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({...defaultFormData});
    const [submitted, setSubmitted] = React.useState(false);
    const [submittedSale, setSubmittedSale] = React.useState({saleItems:[]}); //for caching
    const [modalSale, setModalSale] = React.useState({saleItems:[]}); //for displaying to modal

    const openReceiptModal = function(sale){
        setModalSale({...sale});
    }

    React.useEffect(()=>{
        if(itemQuantities.length > 0){
            setOpen(true);
            setSubmitted(false);
            setModalSale({saleItems:[]});
            setFormData(n=>{
                let formItems = [];
                itemQuantities.forEach((itemQuantity)=>{
                    if(formItems.findIndex(i=>i.id === itemQuantity.item.id) < 0){
                        formItems.push({
                            id:itemQuantity.item.id,
                            quantity:itemQuantity.quantity,
                            subTotal:itemQuantity.subTotal
                        })
                    }
                });

                return {
                    ...defaultFormData,
                    items:formItems
                };
            })
        }
    },[itemQuantities])

    const amountPayable = formData.items.reduce((partial, i)=>partial+parseFloat(i.subTotal), 0);
    const paymentTotal = (()=>{
        let total = 0;
        if(!isNaN(parseFloat(formData.cashReceived))) total+=parseFloat(formData.cashReceived);
        if(!isNaN(parseFloat(formData.electronicPaymentReceived))) total+=parseFloat(formData.electronicPaymentReceived);
        return total;
    })();
    const change = paymentTotal - amountPayable;

    return (
        <SubmitModal
            title='Check Out Items'
            modalOpen={open}
            submitPath={'/api/sales'}
            submitMethod={'post'}
            getSubmitBody={()=>JSON.stringify(formData)}
            onCloseModal={()=>setOpen(false)}
            maxWidth={'md'}
            cancelTitle='Close'
            canSubmit={!submitted}
            additionalButtons={
                <Button disabled={!submitted} onClick={()=>{openReceiptModal(submittedSale)}}>
                    Print Receipt
                </Button>
            }
            onSubmit={(data)=>{
                console.log(data);
                onSubmit();
                setSubmitted(true);
                setSubmittedSale(data.result);
            }}
            contentPadding={'8px'}
            closeModalOnSubmit={false}
        >
            <ReceiptModal sale={modalSale}/>
            <Box position='relative'>
                {submitted ? 
                    <Box sx={{ 
                        position: 'absolute', 
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0 ,
                        display: 'flex',
                        bgcolor:alpha('#000', 0.05),
                        justifyContent: 'center',
                        flexDirection: "column",
                        zIndex:2000
                    }}/>
                : '' }
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{minWidth:30}}>
                                    SKU
                                </TableCell>
                                <TableCell sx={{minWidth:20}}>
                                    Price
                                </TableCell>
                                <TableCell sx={{minWidth:20}}>
                                    Stock
                                </TableCell>
                                <TableCell sx={{minWidth:120}} width={120}>
                                    Quantity
                                </TableCell>
                                <TableCell sx={{minWidth:30}}>
                                    Subtotal
                                </TableCell>
                                <TableCell sx={{minWidth:30}}>
                                    Item Name
                                </TableCell>
                                <TableCell sx={{minWidth:30}}>
                                    Variant
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                itemQuantities.map((itemQuantity, i)=>{
                                    let item = itemQuantity.item;
                                    if(!item) return '';
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell >
                                                {item.sku}
                                            </TableCell>
                                            <TableCell>
                                                {item.price}
                                            </TableCell>
                                            <TableCell>
                                                {item.itemStock.stock}
                                            </TableCell>
                                            <TableCell>
                                                {numberFormat.format(itemQuantity.quantity)}
                                            </TableCell>
                                            <TableCell>
                                                {numberFormat.format(itemQuantity.subTotal)}
                                            </TableCell>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.variant}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={2} sx={{ p:2}}>
                    <Grid xs={12} sm={12} maxWidth={300}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography justifySelf={'end'} >
                                <b>Amount Payable:</b> {numberFormat.format(amountPayable)}
                            </Typography>
                            <Typography justifySelf={'end'}  >
                                <b>Amount Received:</b> 
                            </Typography>
                            <Stack direction={'column'} spacing={2}>
                                <TextField variant="outlined" size='small'
                                    value={formData.cashReceived} 
                                    required type='number' 
                                    onWheel={(e) => {
                                        e.target.blur(); 
                                        setTimeout(() => {
                                            e.target.focus()
                                        }, 0);
                                    }}
                                    onChange={(event)=>setFormData({
                                        ...formData, 
                                        cashReceived: event.target.value
                                    })} 
                                    inputProps={{min:0.00, step:0.01}}
                                    label="Cash Received"
                                />
                                <TextField variant="outlined" size='small'
                                    value={formData.electronicPaymentReceived} 
                                    required type='number' 
                                    onWheel={(e) => {
                                        e.target.blur(); 
                                        setTimeout(() => {
                                            e.target.focus()
                                        }, 0);
                                    }}
                                    onChange={(event)=>setFormData({
                                        ...formData, 
                                        electronicPaymentReceived: event.target.value
                                    })} 
                                    inputProps={{min:0.00, step:0.01}}
                                    label="Electronic Payment Received"
                                />
                                <TextField variant="outlined" size='small'
                                    value={formData.confirmationNumber} 
                                    required={Boolean(formData.electronicPaymentReceived) && formData.electronicPaymentReceived > 0}
                                    disabled={!Boolean(formData.electronicPaymentReceived) || formData.electronicPaymentReceived <= 0}
                                    onChange={(event)=>setFormData({...formData, confirmationNumber:event.target.value})} 
                                    label="Confirmation Number"
                                />
                            </Stack>
                            <Typography justifySelf={'end'} gutterBottom>
                                <b>Payment Total:</b> {numberFormat.format(paymentTotal)}
                            </Typography>
                            <Typography justifySelf={'end'} gutterBottom>
                                <b>Change:</b> {numberFormat.format(change)}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </SubmitModal>
    )
}

export default CheckOutButton;