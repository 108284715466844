import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Typography, Box, Stack, TextField, Button, Divider, Checkbox, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext"
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import LoadingOverlay from '../../Components/LoadingOverlay';
import Grid from '@mui/material/Unstable_Grid2'
import { FetchersContext } from '../../Contexts/FetchersContext';
import SubmitModal from '../../Components/SubmitModal';
import CheckOutButton from '../../Components/Check Out/CheckOutButton';
import RecallButton from '../../Components/Check Out/RecallButton';
import ItemSelectTable from '../../Components/Check Out/ItemSelectTable';

const CheckOut = function(){
    const {user} = React.useContext(UserContext);
    const [filters, setFilters] = React.useState({
        sku:'', name:'', vendor:'', description:''
    });
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [refresh, setRefresh] = React.useState(0);

    return user && (user.permissions.includes('staff') || user.permissions.includes('admin')) ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Check Out
            </Typography>
            <Box sx={{p:2, position:'relative'}}>
                <ItemFilters onFilter={(filters)=>{setFilters(filters)}}/>
            </Box>
            <Box>
                <Typography variant='h6' textAlign={'center'}>Items</Typography>
                <Divider/>
                <ItemSelectTable filters={filters} selectedItems={selectedItems} setSelectedItems={setSelectedItems} refresh={refresh}/>
            </Box>
            <Box sx={{my:1}}>
                <Typography variant='h6' textAlign={'center'}>Check Out</Typography>
                <Divider/>
                <ReceiptForm selectedItems={selectedItems} setSelectedItems={setSelectedItems} 
                    onCheckOut={()=>{
                        setSelectedItems([]);
                        setRefresh(n=>n+1);
                    }}
                />
            </Box>
        </Page>
        
    )
    : user ? 
        <Page>
            You do not have permission to access this page.
        </Page>
    : <Navigate to="/signin" />;
}

const ItemFilters = function({onFilter}){
    const [formData, setFormData] = React.useState({
        sku:"", name:'', vendor:'', description:''
    });

    const handleSubmit = (e)=>{
        e.preventDefault();
        onFilter({...formData});
    }

    return (
        <Box component='form' onSubmit={handleSubmit}>
            <Typography gutterBottom><b>Filter Items:</b></Typography>
            <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                    <Stack direction='column' spacing={1}>
                        <TextField 
                            value={formData.sku}
                            onChange={(e)=>setFormData({...formData, sku: e.target.value})}
                            label="SKU" size="small"
                            variant='outlined'
                        />
                        <TextField 
                            value={formData.name}
                            onChange={(e)=>setFormData({...formData, name: e.target.value})}
                            label="Item Name" size="small"
                            variant='outlined'
                        />
                    </Stack>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Stack direction='column' spacing={1}>
                        <TextField 
                            value={formData.vendor}
                            onChange={(e)=>setFormData({...formData, vendor: e.target.value})}
                            label="Vendor" size="small"
                            variant='outlined'
                        />
                        <TextField 
                            value={formData.description}
                            onChange={(e)=>setFormData({...formData, description: e.target.value})}
                            label="Description" size="small"
                            variant='outlined'
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Box sx={{ mt:2}}>
                <Button type='submit' variant='contained'>
                    Filter
                </Button>
            </Box>
        </Box>
    );
}

//#region receiptform
/**
 * @typedef ItemQuantity
 * @property {Object} item
 * @property {number} quantity
 * @property {number} subTotal
 */
const ReceiptForm = function({selectedItems, setSelectedItems, onCheckOut}){
    const [itemQuantities, setItemQuantities] = React.useState(/**@type {Array<ItemQuantity>} */([]));
    const {user} = React.useContext(UserContext);
    const [error, setError] = React.useState(false);

    React.useEffect(()=>{
        setItemQuantities(n=>{
            let newItemQuantities = [];
            selectedItems.forEach((item)=>{
                let itemQuantity = n.find(i=>i.item.id === item.id);
                if(itemQuantity){
                    newItemQuantities.push(itemQuantity);
                }else{
                    newItemQuantities.push({
                        item: item,
                        quantity:'',
                        subTotal:0
                    })
                }
            })
            return newItemQuantities;
        })
    },[selectedItems])

    React.useEffect(()=>{
        setError(
            itemQuantities.some((iq)=>{
                return isNaN(parseInt(iq.quantity)) || iq.quantity < 1 || iq.quantity > iq.item.itemStock.stock
            })
        );
    },[itemQuantities])

    return (
        <Box position={'relative'}>
            <FormTable selectedItems={selectedItems} setSelectedItems={setSelectedItems} itemQuantities={itemQuantities} setItemQuantities={setItemQuantities}/>
            <Stack sx={{p:2}} direction={'row'} spacing={2}>
                <CheckOutButton onCheckOut={onCheckOut} disabled={error} itemQuantities={itemQuantities}/>
                <RecallButton itemQuantities={itemQuantities} onRecall={onCheckOut} disabled={error}/>
                {
                    user?.permissions.includes('admin') ? (
                        <>
                            <EditReceiptMsgBtn/>
                        </>
                    ): ''
                }
            </Stack>
        </Box>    
    );
}

const FormTable = function({selectedItems, setSelectedItems, setItemQuantities, itemQuantities}){
    
    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth:10, maxWidth:10}}>
                        </TableCell>
                        <TableCell sx={{minWidth:30}}>
                            SKU
                        </TableCell>
                        <TableCell sx={{minWidth:20}}>
                            Price
                        </TableCell>
                        <TableCell sx={{minWidth:20}}>
                            Stock
                        </TableCell>
                        <TableCell sx={{minWidth:20}}>
                            Qty
                        </TableCell>
                        <TableCell sx={{minWidth:20}}>
                            SubTotal
                        </TableCell>
                        <TableCell sx={{minWidth:30}}>
                            Item Name
                        </TableCell>
                        <TableCell sx={{minWidth:30}}>
                            Variant
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        selectedItems.map((item, i)=>{
                            let itemQuantity = itemQuantities.find(iq=>iq.item.id === item.id);
                            if(!itemQuantity) return null;
                            return (
                                <TableRow key={item.id}>
                                    <TableCell >
                                        <Checkbox sx={{p:0}}
                                            checked={Boolean(selectedItems.find(i=>i.id===item.id))}
                                            onChange={(e)=>{
                                                setSelectedItems(items=>{
                                                    items = [...items];
                                                    if(!e.target.checked){
                                                        items.splice(items.findIndex(i=>i.id === item.id),1);
                                                    }
                                                    return items;
                                                })
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell >
                                        {item.sku}
                                    </TableCell>
                                    <TableCell>
                                        {item.price}
                                    </TableCell>
                                    <TableCell>
                                        {item.itemStock.stock}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            size='small' variant='outlined'
                                            label='Qty' type='number'
                                            onWheel={(e) => {
                                                e.target.blur(); 
                                                setTimeout(() => {
                                                    e.target.focus()
                                                }, 0);
                                            }}
                                            onChange={(e)=>{
                                                setItemQuantities(n=>{
                                                    let iq = n.find(i=>i.item.id === item.id);
                                                    iq.quantity = e.target.value;
                                                    if(iq.quantity){
                                                        iq.subTotal = iq.quantity * item.price
                                                    }else{
                                                        iq.subTotal = 0;
                                                    }

                                                    return [...n];
                                                })
                                            }}
                                            fullWidth
                                            required
                                            error={itemQuantity.quantity < 1 || itemQuantity.quantity > item.itemStock.stock}
                                            value={itemQuantity.quantity}
                                            inputProps={{min:1, max:parseInt(item.itemStock.stock), step:1}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {itemQuantity.subTotal}
                                    </TableCell>
                                    <TableCell>
                                        {item.name}
                                    </TableCell>
                                    <TableCell>
                                        {item.variant}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
//#endregion

//#region edit receipt message
const EditReceiptMsgBtn = function(){
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({msg:''});
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        if(open){
            setLoading(true);
            fetchAuthed({
                path:'/api/website'
            },{
                succ:(data)=>{
                    console.log(data);
                    setFormData(n=>({
                        ...n,
                        msg: data.result.receiptMessage,
                        details: data.result.receiptDetails
                    }));
                },
                final:()=>{
                    setLoading(false);
                }    
            })
        }
    },[fetchAuthed, open])

    return (
        <>
            <Button variant='contained' onClick={()=>setOpen(true)}>
                Edit Receipt Message
            </Button>
            <SubmitModal
                modalOpen={open}
                onCloseModal={()=>setOpen(false)}
                submitPath={'/api/website/receiptMessage'}
                submitMethod={'put'}
                onSubmit={()=>setOpen(false)}
                getSubmitBody={()=>JSON.stringify(formData)}
                title={'Edit Receipt Message'}
                succSubmitMessage='Successfully edited.'
                canSubmit={!loading}
            >
                <Box position='relative'>
                    <LoadingOverlay loading={loading}/>
                    <TextField 
                        fullWidth multiline minRows={4} 
                        label='Details' 
                        value={formData.details} 
                        onChange={(e)=>setFormData(n=>{
                            return {
                                ...n,
                                details:e.target.value
                            }
                        })}
                    />
                    <TextField variant='outlined' sx={{mt:2}}
                        label='Thank you message.'
                        fullWidth
                        value={formData.msg} 
                        onChange={(e)=>setFormData(n=>{
                            return {
                                ...n,
                                msg:e.target.value
                            }
                        })}
                    />
                </Box>
            </SubmitModal>
        </>
    )
}
//#endregion

export default CheckOut;
export const routeData = new RouteData({
    path:"/checkout",
    element:<CheckOut />,
},{
    name:"Check Out",
    icon:<ShoppingCartCheckoutIcon />,
    isVisible:(user)=>user && (user.permissions.includes('staff') || user.permissions.includes('admin')),
});