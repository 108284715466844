import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';

import { useLocation } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";

import { UserContext } from "../Contexts/UserContext";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from "@mui/material/Collapse";
import { Badge } from "@mui/material";
import { SocketsContext } from "../Contexts/SocketsContext";

const drawerWidth = 270;

function hasTab(path, user){
    if(Array.isArray(path)){
        return path.every(p=>!hasTab(p));
    } else {
        return !path.noTab && path.isVisible(user);
    }
}

function inPathRecursive(path, pathname){
    if(Array.isArray(path)){
        return path.some(p=>inPathRecursive(p,pathname));
    }else{
        return path.path === pathname || path.inPath(pathname);
    }
}

const MenuList = ({paths, notifCounts})=>{
    return(
        <List>
            {paths.map((path,index)=>{
                return (<MenuTab path={path} key={index} notifCounts={notifCounts}/>);
            })}
        </List>
    );
}

const defaultOpen = ()=>{};
const MenuTab = ({path, tabParent=false, pl=2, opened = false, onOpen=defaultOpen, notifCounts})=>{
    const isArray = Array.isArray(path);

    const location = useLocation();
    const [open, setOpen] = React.useState(inPathRecursive(path, location.pathname));
    const {user} = React.useContext(UserContext);

    React.useEffect(()=>{
        if(inPathRecursive(path, location.pathname)) setOpen(true);
    },[location,path, tabParent]);

    if(!hasTab(path,user)) return null;
    const notifCount = isArray ? 0 : notifCounts[path.path] ? notifCounts[path.path] : 0;
    const inPath = isArray ? false : path.path === location.pathname || path.inPath(location.pathname);

    return (
        <Box>
            {isArray ? 
                <Box>
                    <MenuTab path={path[0]} pl={pl} tabParent={true} opened={open} onOpen={()=>setOpen(n=>!n)} notifCounts={notifCounts}/>
                    <Collapse in={open}>
                        {path.map((p,i)=>{
                            if(i===0) return null;
                            return <MenuTab path={p} key={i} pl={pl+2} notifCounts={notifCounts}/>
                        })}
                    </Collapse>
                </Box>
            :
                <ListItemButton
                    sx={ inPath ? {
                        color: "white",
                        bgcolor: "primary.main",
                        ":hover": {
                            bgcolor: "primary.dark",
                            color: "white",
                        },
                        pl
                    } : {pl}}
                    component={Link}
                    to={path.path}
                >
                    <ListItemIcon
                        sx={inPath ? { color: "white" } : {}}
                    >
                        <Badge color='secondary' badgeContent={notifCount} invisible={notifCount<1} max={99}>
                            {path.icon}
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary={path.name} />
                    { tabParent ? 
                        <IconButton sx={{p:'4px'}} onClick={(e)=>{
                            onOpen();
                            e.stopPropagation();
                            e.preventDefault();
                        }} onMouseDown={(e)=>e.stopPropagation()} onTouchStart={e=>e.stopPropagation()}>{opened ? <ExpandLessIcon/> : <ExpandMoreIcon/>}</IconButton>
                    : null }
                </ListItemButton>
            }
        </Box>
    );
        
}

function ResponsiveDrawer(props) {
    const { window } = props;
    const {adminSocket} = React.useContext(SocketsContext);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const [notifCounts, setNotifCounts] = React.useState({
    });
    console.log('drawer');
    React.useEffect(()=>{
        setMobileOpen(false);
    },[location]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const notifTotal = (()=>{
        let count = 0;
        Object.values(notifCounts).forEach(n=>count+=n);
        return count;
    })();

    React.useEffect(()=>{
        if(!adminSocket) return;
        let notifSources = [];
        function registerSockets(path){
            if(path.notifSource){
                notifSources.push({source:path.notifSource,path:path.path});
            }
        }
        props.pathGroups.forEach(path=>{
            if(Array.isArray(path)){
                path.forEach(p=>registerSockets(p));
            }else{
                registerSockets(path);
            }
        })
        notifSources.forEach(ns=>{
            adminSocket.emit(ns.source+':get',(res)=>{
                if(res.status === 'ok'){
                    setNotifCounts(n=>{
                        return{
                            ...n,
                            [ns.path]:res.result
                        }
                    })
                }
            });
            adminSocket.on(ns.source+':seen',()=>{
                setNotifCounts(n=>{
                    return {
                        ...n,
                        [ns.path]: 0
                    }
                })
            })
            adminSocket.on(ns.source+':new',()=>{
                setNotifCounts(n=>{
                    return {
                        ...n,
                        [ns.path]: n[ns.path] ? n[ns.path]+1: 1
                    }
                });
            })
        })

        return ()=>{
            notifSources.forEach(ns=>{
                adminSocket.off(ns.source+':get');
                adminSocket.off(ns.source+':seen');
                adminSocket.off(ns.source+':new');
            })
        }
    },[props.pathGroups,adminSocket]);

    const drawer = React.useMemo(()=>(
        <div>
            <Toolbar />
            <Divider />
            <MenuList paths={props.pathGroups} notifCounts={notifCounts}/>
        </div>
    ),[props.pathGroups, notifCounts]);

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{position:'fixed', bgcolor:'whitesmoke', minWidth:'100vw', minHeight:'100vh', zIndex:-100}}/>
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    mr: { md: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <Avatar alt="Chibi" src={process.env.PUBLIC_URL + '/android-chrome-192x192.png'} sx={{mr:2}} />
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{ flexGrow: 1 }}
                        component="div"
                    >
                        {props.title}
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: "none" } }}
                    >
                        <Badge color='secondary' badgeContent={notifTotal} invisible={notifTotal<1} max={99}>
                            <MenuIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Box
                component="main"
                minHeight="100vh"
                sx={{
                    flexGrow: 1,
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    anchor="right"
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    anchor="right"
                    sx={{
                        display: { xs: "none", md: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

export default ResponsiveDrawer;
