import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../Contexts/UserContext";

export const FetchersContainer = ({setFetchers})=>{
    const {setUser} = useContext(UserContext);
    const [notifOpen, setNotifOpen]  = useState(false);
    const notifData = useRef({
        severity:'error',
        message:'Error'
    });

    useEffect(()=>{
        const fetchCSRF = async ({succ, final, err})=>{
            fetch('/api/csrf-token')
            .then(response => {
                if(!response.ok) throw Error(response.statusText);
                return response.json();
            })
            .then(csrfToken=>succ(csrfToken.result))
            .catch((error)=>{
                console.log("error!:");
                console.log(error);
                openNotification('error', 'Error: '+error.message);
                if(err) err(error);
            })
            .finally(()=>{
                if(final) final();
            });
        
        };

        const fetchAuthed = ({path, method, headers, body}, {succ, final, err, succMessage})=>{
            console.time('fetch');
            fetch(path,{
                method:method,
                headers:headers,
                body:body
            })
            .then(response => {
                console.timeEnd('fetch');
                return new Promise((succ,err)=>{
                    response.json().then(json=>{
                        if(response.ok) succ(json);
                        else err(new Error(json.error,{ cause:response.status }));
                    }).catch(err);
                });
            })
            .then(data=>{
                succ(data);
                if(succMessage) openNotification('success', succMessage);
            })
            .catch((error)=>{
                console.log("error!:");
                console.log(error);
                openNotification('error', 'Error: '+error.message);
                switch(error.cause){
                    case 401:
                        setUser(null);
                        break;
                    case 403:
                        break;
                    default:
                        if(err) err(error);
                        break;        
                }
            })
            .finally(()=>{
                if(final) final();
            });
    
        }

        setFetchers({
            fetchCSRF,
            fetchAuthed
        })
        
    },[setFetchers, setUser,]);
    
    const openNotification=(severity, message)=>{
        notifData.current = {
            severity,
            message
        };
        setNotifOpen(true);
    }

    return (
        <Box>
            <Snackbar
                open={notifOpen}
                autoHideDuration={3000}
                onClose={(event)=>setNotifOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={(event)=>setNotifOpen(false)} severity={notifData.current.severity} sx={{ width: '100%' }}>
                    {notifData.current.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};