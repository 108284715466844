import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import { FetchersContext } from "../Contexts/FetchersContext";

const SubmitModal = ({children, modalOpen, onCloseModal, maxWidth, submitPath, additionalButtons=null, contentPadding=2, onSubmit, title, cancelTitle='Cancel',closeModalOnSubmit=true, canSubmit=true, submitMethod,submitLabel="Confirm", getSubmitBody, succSubmitMessage = 'Success', submitContentType='application/json'})=>{
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (event)=>{
        event.preventDefault();
        setLoading(true);
        fetchCSRF({
            succ:(csrf)=>{
                fetchAuthed({
                    path:submitPath,
                    method:submitMethod,
                    body:getSubmitBody ? getSubmitBody() : undefined,
                    headers:{
                        'x-csrf-token':csrf,
                        ...submitContentType ? {'content-type':submitContentType} : {}
                    }
                },{
                    succ:(data)=>{
                        onSubmit(data);
                        if(closeModalOnSubmit) onCloseModal();
                    },
                    final:()=>{
                        setLoading(false);
                    },
                    succMessage: succSubmitMessage,
                })
            }
        });
    }

    return (
        <Dialog
            open={modalOpen}
            onClose={onCloseModal}
            component='form'
            onSubmit={handleSubmit}
            fullWidth maxWidth={maxWidth || 'xs'}
        >
            <DialogTitle >
                {title}
            </DialogTitle>
            <DialogContent dividers sx={{p:contentPadding}}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseModal} disabled={loading}>{cancelTitle}</Button>
                {additionalButtons}
                <Button type="submit" disabled={!canSubmit || loading}>
                    {submitLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubmitModal;