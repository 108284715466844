
import React from 'react';
import AnnouncementMainCard from './AnnouncementMainCard';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { Pagination } from '@mui/material';
import AnnouncementCard from './AnnouncementCard';

const AnnouncementCardList = ()=>{
    const [pagination, setPagination] = React.useState({
        page:0,
        rowsPerPage:5,
    });
    const [listData, setListData] = React.useState({
        data:[],
        count:0
    });

    React.useEffect(()=>{
        let params = {
            limit:pagination.rowsPerPage,
            offset:pagination.rowsPerPage*pagination.page,
        };
        fetch('/api/announcements?'+new URLSearchParams(params))
        .then(async(response)=>{
            if(!response.ok) throw new Response(response.statusText, {status:response.status});
            return response.json();
        })
        .then(data=>{
            setListData({
                data:data.result,
                count:data.count
            })
        });
    },[pagination]);

    return(
        <Box>
            <Box
                sx={{
                    width:'90%',
                    mx:'auto',
                }}
            >
                {listData.data.length > 0 ? 
                    <AnnouncementMainCard 
                        announcementData={listData.data[0]}
                    />
                :null}
                <Box sx={{
                    mt:2,
                }}>
                    <Grid container direction='row' spacing={2} justifyContent='center'>
                        {listData.data.map((announcement, id)=>{
                            if(id === 0) return null;
                            return(
                                <Grid xs={6} md={3} key={id} >
                                    <AnnouncementCard announcementData={announcement}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
            <Box sx={{
                mt:2,
                justifyContent:"center",
                display:'flex'
            }}>
                <Pagination 
                    count={Math.ceil(listData.count / pagination.rowsPerPage)} 
                    page={pagination.page+1} 
                    onChange={(e,v)=>setPagination(n=>({...n, page:v-1}))}
                />
            </Box>
        </Box>
    );
}

export default AnnouncementCardList;