import React from "react";
import { UserContext } from "../Contexts/UserContext";

import io from 'socket.io-client';

const SocketContainer = ({setSockets})=>{
    const {user} = React.useContext(UserContext);

    React.useEffect(()=>{
        /*if(user){
            const socket = io('http://localhost:3001/',{
                withCredentials:true
            });

            console.log('attepmting socket connection');
            socket.on('connect', () => {
                console.log('socket connected');
                socket.emit('whoami', (user)=>{
                    console.log('whoami:');
                    console.log(user);
                })
            });

            socket.on('connect_error', (err)=>{
                console.log(err.message);
            })

            socket.on('disconnect', () => {
                console.log('socket disconnected');
            });

            let adminSocket;
            if(user.permissions.includes('admin')){
                adminSocket = io('http://localhost:3001/admin',{
                    withCredentials:true
                });

                console.log('attempting admin socket connection');
                adminSocket.on('connect', () => {
                    console.log('admin socket connected');
                    socket.emit('whoami', (user)=>{
                        console.log('admin whoami:');
                        console.log(user);
                    })
                });
    
                adminSocket.on('connect_error', (err)=>{
                    console.log('admin error: '+err.message);
                })
    
                adminSocket.on('disconnect', () => {
                    console.log('admin socket disconnected');
                });

                adminSocket.on('initialized', (res)=>{
                    console.log('initialized successfully:');
                    console.log(res);
                    setSockets({
                        socket, adminSocket
                    })
                });
            }
            return () => {
                socket.off('connect');
                socket.off('connect_error');
                socket.off('disconnect');
                if(adminSocket){
                    adminSocket.off('connect');
                    adminSocket.off('connect_error');
                    adminSocket.off('disconnect');
                    adminSocket.off('initialized');
                }
            };
        }*/
    },[user, setSockets]);
}

export default SocketContainer;