import React from "react"

import { Box, ButtonBase, Modal, Typography } from "@mui/material";
import moment from "moment";

const AnnouncementInfo = ({announcement})=>{
    const [openModal, setOpenModal] = React.useState(false);

    return (
        <Box p={2}>
            {announcement.imgName ? 
                <ButtonBase sx={{
                    display:'block',
                    width:'90%',
                    height: {
                        xs:200,
                        sm:250,
                        md:300,
                    },
                    mb:2,
                    mx:'auto'
                }} onClick={()=>setOpenModal(true)}>
                    <img style={{
                        display:'block',
                        width:'100%',
                        height:'100%',
                        objectFit:'contain',
                        borderRadius:'inherit',
                        backgroundColor:'lightgray',
                    }} alt={announcement.title} src={'/api/announcements/image/'+announcement.imgName}/>
                </ButtonBase>
            : null }
            <Typography variant="h6">{announcement.title}</Typography>
            <Typography gutterBottom whiteSpace='pre-wrap'>{announcement.text}</Typography>
            <Typography align='right' variant='body2' gutterBottom><i>Posted: {moment(announcement.dateCreated).format('ll')}</i></Typography>
            <Typography align='right' variant='body2' ><i>Last Edited: {moment(announcement.dateUpdated).format('ll')}</i></Typography>
            <Modal open={openModal} onClose={()=>setOpenModal(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <img style={{
                        display:'block',
                        maxWidth:'90vw',
                        maxHeight:'90vh'
                    }} alt={announcement.title} src={'/api/announcements/image/'+announcement.imgName}/>
                </Box>
            </Modal>
        </Box>
    )
}

export default AnnouncementInfo;