import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment";

const defaultSale = {
    saleItems:[]
};

const SaleDetailsModal =({sale=defaultSale})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(sale.id)
            setOpen(true);
    },[sale]);

    return (
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            fullWidth maxWidth={'md'}
        >
            <DialogTitle >
                Sale
            </DialogTitle>
            <DialogContent dividers sx={{p:0}}>
                <Box sx={{p:2}}>
                    <Grid container>
                        <Grid xs={12} sm={6}>
                            <Detail label='Receipt No.' value={sale.receiptNumber}/>
                            <Detail label='Date of Sale' value={moment(sale.dateOfSale).format('LL')}/>
                            <Detail label='Handler' value={sale.handlerUsername}/>
                            <Detail label='Total Price' value={sale.priceTotal}/>
                            <Detail label='Tax Total' 
                                value={(parseFloat(sale.priceTotal)-parseFloat(sale.priceTaxed)).toFixed(2)}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <Detail label='Cash Payment Received' value={sale.cashPayment}></Detail>
                            <Detail label='E-Payment Received' value={sale.electronicPayment}></Detail>
                            <Detail label='Confirmation No.' value={sale.confirmationNumber}></Detail>
                            <Detail label='Total Payment Received' 
                                value={
                                    parseFloat(sale.cashPayment)+parseFloat(sale.electronicPayment)
                                }
                            />
                            <Detail label='Change Given' value={sale.changeGiven}/>
                        </Grid>
                    </Grid>
                </Box>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    SKU
                                </TableCell>
                                <TableCell>
                                    Item Name|Variant
                                </TableCell>
                                <TableCell>
                                    Price
                                </TableCell>
                                <TableCell>
                                    Tax
                                </TableCell>
                                <TableCell>
                                    Qty
                                </TableCell>
                                <TableCell>
                                    Sub-Total
                                </TableCell>
                                <TableCell>
                                    Tax Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sale.saleItems?.map((saleItem)=>{
                                    return (
                                        <TableRow key={saleItem.id}>
                                            <TableCell>
                                                {saleItem.sku}
                                            </TableCell>
                                            <TableCell>
                                                {saleItem.name} | {saleItem.variant}
                                            </TableCell>
                                            <TableCell>
                                                {saleItem.price}
                                            </TableCell>
                                            <TableCell>
                                                {saleItem.tax}%
                                            </TableCell>
                                            <TableCell>
                                                {saleItem.quantity}
                                            </TableCell>
                                            <TableCell>
                                                {saleItem.priceTotal}
                                            </TableCell>
                                            <TableCell>
                                                {(parseFloat(saleItem.priceTotal) - parseFloat(saleItem.priceTaxed)).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}


export default SaleDetailsModal;