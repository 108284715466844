import React from "react";
import SubmitModal from "../SubmitModal";
import { Alert } from "@mui/material";

const defaultSale = {};

const DeleteSaleModal = ({sale = defaultSale, onDelete})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(sale.id){
            setOpen(true);
        }
    }, [sale]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully deleted sale."
            submitMethod="delete"
            submitLabel="Delete"
            submitPath={'/api/sales/'+sale.id}
            onSubmit={onDelete}
            title='Delete Sale'
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
        >
            <Alert severity="warning">Delete sale</Alert>
        </SubmitModal>
    )
}

export default DeleteSaleModal;
