import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import FullTable from "../FullTable";
import { Alert, Button,Stack,TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import SubmitModal from "../SubmitModal";
import moment from "moment";
import ItemDetailsModal from "./ItemDetailsModal";
import ImportItemsModal from "./ImportItemsModal";

const getColumns = ()=>{
    const columns = [{
        id:"dateCreated",
        label: "Date Added",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateCreated;
            if(date) return moment(date).format('ll');
        }
    },{
        id:'sku',
        label:'SKU',
        minWidth:50
    },{
        id: "name",
        label: "Item Name",
        minWidth:60,
        sortable:true,
    },{
        id: "variant",
        label: "Variant",
        minWidth:60,
        sortable:true,
    },{
        id: "description",
        label: "Description",
        minWidth:60,
        sortable:true,
    },{
        id: "quantity",
        label: "Quantity",
        minWidth:30,
        sortable:true,
    },{
        id: "price",
        label: "Price",
        minWidth:30,
        sortable:true,
    },{
        id: "notes",
        label: "Notes",
        minWidth:60,
        sortable:true,
    }];

    return columns;
};

const getActionColumn = (data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:110,
        width:110,
        actions:[]
    };
    col.actions.push({
        title:'Details',
        fn:(value)=>{
            data.openDetailsItemModal(value);
        },
        icon:<MoreHorizIcon fontSize="inherit"/>
    });
    col.actions.push({
        title:'Remove',
        fn:(value)=>{
            data.openDeleteItemModal(value);
        },
        icon:<DeleteIcon fontSize="inherit"/>
    });
    return col;
}


const ItemApprovalTable = function(){
    const [refresh, setRefresh] = React.useState(0);
    const [deleteItem, setDeleteItem] = React.useState({name:''});
    const [editItem, setEditItem] = React.useState({});
    const [detailsItem, setDetailsItem] = React.useState({});

    const openDetailsItemModal = (item)=>{
        setDetailsItem({
            ...item
        });
    }

    const openDeleteItemModal = (item)=>{
        setDeleteItem({
            ...item
        });
    }

    const openEditItemModal = (item)=>{
        setEditItem({
            ...item
        })
    }
    
    const columns = React.useRef([
        ...getColumns(),
        getActionColumn({ openDeleteItemModal,  openEditItemModal, openDetailsItemModal})
    ])

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            columns={columns.current}
            dataPath = '/api/items/approval'
            refresh={refresh}
            leftHeading={
                <Stack spacing={2} direction={'row'}>
                    <AddItemButton onAdd={()=>setRefresh(n=>n+1)}/>
                    <ImportItemsButton onImport={()=>setRefresh(n=>n+1)}/>
                </Stack>
            }
            searchOptions={['SKU','Item Name', 'Variant', 'Description']}
        />
    ),[refresh]);

    return (
        <>
            <EditItemModal
                item={editItem}
                onEdit={()=>setRefresh(n=>n+1)}
            />
            <DeleteItemModal
                onDelete={()=>setRefresh(n=>n+1)}
                item={deleteItem}
            />
            <ItemDetailsModal
                item={detailsItem}
            />
            {mOrderTable}
        </>
    );
}

const EditItemModal = ({onEdit, item})=>{
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        name:'',variant:'',description:'',price:'',quantity:'',notes:'',
    });

    React.useEffect(()=>{
        if(item.id) {
            setFormData({
                name:item.name,
                variant:item.variant,
                description: item.description,
                price: item.price,
                quantity: item.quantity,
                notes: item.notes
            });
            setOpen(true);
        }
    },[item]);

    return (
        <ItemFormModal
            succSubmitMessage="Successfully edited item."
            submitMethod="put"
            submitLabel="Save"
            submitPath={'/api/items/approval/'+item.id}
            onSubmit={onEdit}
            title='Edit Item'
            formData = {formData}
            onFormChange = {(val)=>setFormData(val)}
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
        >
        </ItemFormModal>
    );
}

const AddItemButton = ({onAdd})=>{
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <AddItemModal
                modalOpen={open}
                onCloseModal={()=>setOpen(false)}
                onSubmit={onAdd}
            />
            <Button
                onClick={()=>setOpen(true)}
                variant='contained'
            >
                Add Item
            </Button>
        </>
    );
}

const ImportItemsButton = ({onImport})=>{
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <ImportItemsModal
                open={open}
                onClose={()=>setOpen(false)}
                onImport={onImport}
            />
            <Button
                variant='contained'
                onClick={()=>{setOpen(true)}}
            >
                Import Items
            </Button>
        </>
    );
};

const AddItemModal = ({modalOpen, onCloseModal, ...props})=>{
    const [formData, setFormData] = React.useState({
        name:'',variant:'',description:'',price:'',quantity:'',notes:'',
    });
    React.useEffect(()=>{
        if(modalOpen){
            setFormData({
                name:'',variant:'',description:'',price:'',quantity:'',notes:''
            });
        }
    },[modalOpen]);

    return (
        <ItemFormModal
            {...props}
            formData = {formData}
            onFormChange = {(val)=>setFormData(val)}
            modalOpen={modalOpen}
            onCloseModal={onCloseModal}
            title='Add Item for Approval'
            submitPath="/api/items/approval"
            succSubmitMessage="Successfully added item."
            submitMethod="post"
            submitLabel="Add"
        >
        </ItemFormModal>
    );
}

const ItemFormModal = ({
    formData, 
    onFormChange,
    ...props
})=>{
    return(
        <SubmitModal
            {...props}
            getSubmitBody = {()=>{
                return JSON.stringify(formData);
            }}
            maxWidth={'xs'}
        >
            <Grid container spacing={3}>
                <Grid 
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                    }}
                    xs={12}
                    sm={12}
                >
                    <TextField variant="standard" sx={{mt:1}}
                        value={formData.name} 
                        required
                        onChange={(event)=>onFormChange({
                            ...formData, 
                            name:event.target.value
                        })} 
                        label="Item Name"
                    />
                    <TextField variant="standard" sx={{mt:1}}
                        value={formData.variant} 
                        onChange={(event)=>onFormChange({...formData, variant:event.target.value})} 
                        label="Variant"
                        required
                        inputProps={{maxLength:3}}
                    />
                    <TextField variant="standard" sx={{mt:1}}
                        value={formData.description} 
                        onChange={(event)=>onFormChange({...formData, description:event.target.value})} 
                        label="Description"
                    />
                    <TextField variant="standard" sx={{mt:1}}
                        value={formData.quantity} 
                        required type='number' 
                        onWheel={(e) => {
                            e.target.blur(); 
                            setTimeout(() => {
                                e.target.focus()
                            }, 0);
                        }}
                        onChange={(event)=>onFormChange({
                            ...formData, 
                            quantity: event.target.value
                        })} 
                        label="Quantity"
                        inputProps={{min:1}}
                    />
                    <TextField variant="standard" sx={{mt:1}}
                        value={formData.price} 
                        required type='number' 
                        onWheel={(e) => {
                            e.target.blur(); 
                            setTimeout(() => {
                                e.target.focus()
                            }, 0);
                        }}
                        onChange={(event)=>onFormChange({
                            ...formData, 
                            price: event.target.value
                        })} 
                        inputProps={{min:0.00, step:0.01}}
                        label="Price"
                    />
                    <TextField variant="outlined" sx={{mt:3}}
                        value={formData.notes} 
                        onChange={(event)=>onFormChange({...formData, notes:event.target.value})} 
                        multiline rows={4} label="Notes" 
                    />
                </Grid>
            </Grid>
        </SubmitModal>
    );
}

const DeleteItemModal = ({onDelete, item})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(item.id) {
            setOpen(true);
        }
    },[item]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully deleted item."
            submitMethod="delete"
            submitLabel="Delete"
            submitPath={"/api/items/approval/"+item.id}
            onSubmit={onDelete}
            title='Delete Item'
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
        >
            <Alert severity="warning">Delete item {item.name}?</Alert>
        </SubmitModal>
    );
}

export default ItemApprovalTable;