import React from "react";
import FullTable from "../FullTable";
import moment from "moment";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ItemDetailsModal from "./ItemDetailsModal";
import { Box, Button } from "@mui/material";
import ExportModal from "../ExportModal";
import Grid from '@mui/material/Unstable_Grid2';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { FetchersContext } from "../../Contexts/FetchersContext";
import LoadingOverlay from "../LoadingOverlay";
import { ArrowDownward } from "@mui/icons-material";

const getColumns = ()=>{
    const columns = [{
        id:"dateApproved",
        label: "Date Approved",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.itemApproval?.dateApproved;
            if(date) return moment(date).format('ll');
        }
    },{
        id:"dateAdded",
        label: "Date Added",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateCreated;
            if(date) return moment(date).format('ll');
        }
    },{
        id:'sku',
        label:'SKU',
        minWidth:50,
        sortable:true
    },{
        id:'name',
        label:'Name|Variant',
        minWidth:30,
        getValue:(v)=>{
            return v.name+' | '+v.variant
        }
    },{
        id:'description',
        label:'Description',
        minWidth:60
    },{
        id:'price',
        label:'Price',
        minWidth:30
    },{
        id:'tax',
        label:'Tax%',
        minWidth:30,
        getValue:(v)=>{
            return v.itemApproval?.tax
        }
    },{
        id: "stock",
        label: "Stock",
        minWidth:20,
        sortable:true,
        getValue:(v)=>{
            return v.itemStock?.stock
        }
    },{
        id: "sold",
        label: "Sold",
        minWidth:20,
        sortable:true,
        getValue:(v)=>{
            return v.itemStock?.sold
        }
    },{
        id: "recalled",
        label: "Recalled",
        minWidth:20,
        sortable:true,
        getValue:(v)=>{
            return v.itemStock?.recalled
        }
    },{
        id: "notes",
        label: "Notes",
        minWidth:100
    }];

    return columns;
};

const getActionColumn = (data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:80,
        width:80,
        actions:[]
    };
    col.actions.push({
        title:'Details',
        fn:(value)=>{
            data.openDetailsItemModal(value);
        },
        icon:<MoreHorizIcon fontSize="inherit"/>
    });
    return col;
}


const ItemInventoryTable = function(){
    const [refresh, ] = React.useState(0);
    
    const [detailsItem, setDetailsItem] = React.useState({});

    const openDetailsItemModal = (item)=>{
        setDetailsItem({
            ...item
        });
    }


    const columns = React.useRef([
        ...getColumns(),
        getActionColumn({ openDetailsItemModal})
    ])

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            columns={columns.current}
            dataPath = '/api/items/inventory'
            refresh={refresh}
            searchOptions={['SKU', 'Item Name', 'Variant', 'Description']}
        />
    ),[refresh]);

    return (
        <>
            <ItemDetailsModal item={detailsItem}/>
            <InventorySummary/>
            {mOrderTable}
            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} p={2}>
                <ExportButton/>
            </Box>
        </>
    );
}

const numberFormat = new Intl.NumberFormat('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
const InventorySummary = ()=>{
    const [open, setOpen] = React.useState(false);
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [loading, setLoading] = React.useState(false);
    const [summary, setSummary] = React.useState({});

    React.useEffect(()=>{
        if(open){
            setLoading(true);
            fetchAuthed({
                path:'/api/items/inventory/report'
            },{
                succ:(data)=>{
                    setSummary(data.result);
                },
                final:()=>{
                    setLoading(false);
                }
            })
        }
    },[open, fetchAuthed])

    return (
        <Accordion expanded={open} onChange={(event, isExpanded)=>setOpen(isExpanded)}>
            <AccordionSummary expandIcon={<ArrowDownward />}>
                Inventory Summary
            </AccordionSummary>
            <AccordionDetails sx={{position:'relative'}}>
                <LoadingOverlay loading={loading}/>
                <Grid container sx={{px:2}}>
                    <Grid xs={12} sm={6}>
                        <Detail label='Stock Price Total' value={numberFormat.format(summary.inventoryPriceTotal)}/>
                        <Detail label='Stock Tax Total' value={numberFormat.format(summary.inventoryTaxTotal)}/>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Detail label='Stock Quantity Total' value={summary.inventoryStockTotal}/>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}

const ExportButton = ()=>{
    const [modalOpen, setModalOpen] = React.useState(false);

    const getCsvData = (data)=>{
        let csvData = [];
        csvData.push([
            'Date Approved', 'Approver', 
            'Date Added', 'Vendor', 
            'SKU', 'Item Name', 'Variant', 'Description',
            'Price', 'Tax%',
            'Stock', 'Sold', 'Recalled',
            'Location', 'Notes' 
        ]);

        data.forEach(item=>{
            csvData.push([
                moment(item.itemApproval?.dateApproved).format('LL'), item.itemApproval?.approverUsername, 
                moment(item.dateCreated).format('LL'), item.vendor?.username,
                item.sku, item.name, item.variant, item.description,
                item.price, item.itemApproval?.tax,
                item.itemStock?.stock, item.itemStock?.sold, item.itemStock?.recalled,
                item.itemApproval?.location, item.notes
            ])
        })
        return csvData;
    }

    return (
        <>
            <Button variant='contained' onClick={()=>setModalOpen(true)}>Export</Button>
            <ExportModal
                open={modalOpen}
                onClose={()=>setModalOpen(false)}
                title={"Export Inventory"}
                path={'/api/items/inventory'}
                fileName={'inventory'}
                getCsvData={getCsvData}
            />
        </>
    )
}

export default ItemInventoryTable;