import Paper from "@mui/material/Paper";

const Page = ({children,sx={},...props})=>{
    return (
        <Paper
            sx={{
                mx: "auto",
                mt: { xs: 0, sm: 4 },
                maxWidth: { xs: "100vw", sm: "80vw", md:'75vw', lg:'65vw' },
                ...sx
            }}
            {...props}
        >
            {children}
        </Paper>
    );
}

export default Page;