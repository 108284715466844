import React from "react";
//import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Typography from '@mui/material/Typography';

import RouteData from "../RouteData";
import { UserContext } from "../../Contexts/UserContext";
import { Navigate } from "react-router-dom";
import Page from "../../Components/Page";
import { Box } from "@mui/material";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import moment from "moment";
import FullTable from "../../Components/FullTable";
import ReceiptModal from "../../Components/Sales/ReceiptModal";
import SaleDetailsModal from "../../Components/Sales/SaleDetailsModal";
import DeleteSaleModal from "../../Components/Sales/DeleteSaleModal";
import DeleteIcon from '@mui/icons-material/Delete';

const Receipts = ()=>{
    const {user} = React.useContext(UserContext);

    return (
        user && (user.permissions.includes('admin') || user.permissions.includes('staff'))? 
        <Page>
            <Typography variant="h6" sx={{p:2}}>
                Receipts
            </Typography>
            <Box>
                <ReceiptsTable/>
            </Box>
        </Page>
        : user ? 
        <Typography>
            Access restricted.
        </Typography>
        :
        <Navigate to='/signin'/>
    );
}

const getColumns = ()=>{
    const columns = [{
        id:"dateOfSale",
        label: "Sale Date",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateOfSale;
            if(date) return moment(date).format('ll');
        }
    },{
        id:'receiptNumber',
        label:'Receipt Number',
        minWidth:50
    },{
        id:'priceTotal',
        label:'Total Price',
        minWidth:50
    },{
        id:'handler',
        label:'Handler',
        minWidth:50,
        getValue:(v)=>{
            return v.handlerUsername
        }
    }];

    return columns;
};

const getActionColumn = (data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:140,
        width:140,
        actions:[]
    };
    col.actions.push({
        title:'Details',
        fn:(value)=>{
            data.openDetailsSaleModal(value);
        },
        icon:<MoreHorizIcon fontSize="inherit"/>
    });
    col.actions.push({
        title:'Receipt',
        fn:(value)=>{
            data.openReceiptSaleModal(value);
        },
        icon:<ReceiptIcon fontSize="inherit"/>
    });
    if(data.user && data.user.permissions.includes('admin')){
        col.actions.push({
            title:'Remove',
            fn:(value)=>{
                data.openDeleteSaleModal(value);
            },
            icon:<DeleteIcon fontSize="inherit"/>
        });
    }
    return col;
}


const ReceiptsTable = function(){
    const [refresh, setRefresh] = React.useState(0);
    const [receiptSale, setReceiptSale] = React.useState({saleItems:[]});
    const [detailsSale, setDetailsSale] = React.useState({saleItems:[]});
    const [deleteSale, setDeleteSale] = React.useState({});
    const {user} = React.useContext(UserContext);

    const openDeleteSaleModal = (sale)=>{
        setDeleteSale({...sale});
    }

    const openReceiptSaleModal = (sale)=>{
        setReceiptSale({...sale});
    }

    const openDetailsSaleModal = (sale)=>{
        setDetailsSale({...sale});
    }

    const columns = React.useRef([
        ...getColumns(),
        getActionColumn({openReceiptSaleModal, openDetailsSaleModal, user, openDeleteSaleModal})
    ])

    const mTable = React.useMemo(()=>(
        <FullTable
            columns={columns.current}
            dataPath = '/api/sales'
            refresh={refresh}
            searchOptions={['Receipt Number']}
        />
    ),[refresh, columns]);

    return (
        <>
            <DeleteSaleModal sale={deleteSale} onDelete={()=>setRefresh(n=>n+1)}/>
            <SaleDetailsModal sale={detailsSale}/>
            <ReceiptModal sale={receiptSale}/>
            {mTable}
        </>
    );
}

export const routeData = new RouteData({
    path:'/sales/receipts',
    element: <Receipts/>
},{
    name: 'Receipts',
    icon: <FormatListBulletedIcon/>,
    isVisible: (user)=>{
        return user && (user.permissions.includes('admin') || user.permissions.includes('staff'));
    }
})