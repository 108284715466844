import React from "react";
import FullTable from "../FullTable";
import moment from "moment";
import SoldTabs from "./SoldTabs";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowDownward } from "@mui/icons-material";
import { FetchersContext } from "../../Contexts/FetchersContext";
import LoadingOverlay from "../LoadingOverlay";
import ExportModal from "../ExportModal";

const getColumns = ()=>{
    const columns = [{
        id:"dateSold",
        label: "Date Sold",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateSold;
            if(date) return moment(date).format('ll');
        }
    },{
        id:"dateCreated",
        label: "Date Added",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateCreated;
            if(date) return moment(date).format('ll');
        }
    },{
        id:"dateApproved",
        label: "Date Approved",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateApproved;
            if(date) return moment(date).format('ll');
        }
    },{
        id:"receiptNumber",
        label: "Receipt No.",
        minWidth:30,
    },{
        id:'sku',
        label:'SKU',
        minWidth:50,
    },{
        id:'name',
        label:'Name',
        minWidth:50,
    },{
        id:'variant',
        label:'Variant',
        minWidth:50,
        sortable:true
    },{
        id:'recalled',
        label:'Recalled',
        minWidth:50,
        getValue:(v)=>{
            if(v.priceTotal > 0){
                return 'No'
            }else{
                return "Yes"
            }
        }
    },{
        id: "quantity",
        label: "Qty",
        minWidth:30,
    },{
        id: "price",
        label: "Price",
        minWidth:30,
    },{
        id: "tax",
        label: "Tax%",
        minWidth:30,
    },{
        id: "taxComputed",
        label: "Tax",
        minWidth:30,
        getValue:(v)=>{
            return (parseFloat(v.priceTotal) - parseFloat(v.priceTaxed)).toFixed(2)
        }
    },{
        id:"priceTaxed",
        label:"Encashment",
        minWidth:30
    }];

    return columns;
};

const numberFormat = new Intl.NumberFormat('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
const ItemSoldTable = function(){
    const [loading, setLoading] = React.useState(false);
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [salesReport, setSalesReport] = React.useState({vendorSalesReports:[]});
    const columns = React.useRef([
        ...getColumns()
    ])

    const [filters, setFilters] = React.useState({
        soldFilter:'all'
    });

    const handleSetRange = (dateRange)=>{
        setFilters(n=>{
            return {
                ...n,
                from:dateRange.from,
                to:dateRange.to
            }
        });
        setLoading(true);
        fetchAuthed({
            path:'/api/sales/vendorreport?'+new URLSearchParams(dateRange).toString()
        },{
            succ:(data)=>{
                setSalesReport(data.result);
            },
            final:()=>{
                setLoading(false);
            }
        })
    }

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            columns={columns.current}
            dataPath = '/api/sales/solditems'
            searchOptions={['SKU', 'Item Name', 'Variant', 'Receipt Number']}
            urlSearchParams={filters}
            getRowId={(row)=>row.receiptNumber+row.sku}
        />
    ),[filters]);

    return (
        <>
            <DateRangeSelector onSelectRange={handleSetRange}/>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ArrowDownward />}
                >
                <Typography>Summary</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{position:'relative'}}>
                    <LoadingOverlay loading={loading}/>
                    <Grid container sx={{px:2}}>
                        <Grid xs={12} sm={6}>
                            <Detail label='Sold Total' value={numberFormat.format(salesReport.soldTotal)}/>
                            <Detail label='Tax Total' value={numberFormat.format(salesReport.taxTotal)}/>
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <Detail label='Encashment Total' value={numberFormat.format(salesReport.taxedTotal)}/>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <SoldTabs tabValue={filters.soldFilter}
                setTabValue={(v)=>{setFilters({soldFilter:v})}}
            />
            {mOrderTable}
            
            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} p={2}>
                <ExportButton/>
            </Box>
        </>
    );
}
const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}


const DateRangeSelector = ({onSelectRange})=>{
    const [formData, setFormData] = React.useState({
        from:'', to:''
    })

    const handleSubmit = function(e){
        e.preventDefault();
        onSelectRange(formData);
    }

    return (
        <Box sx={{py:1,px:2}} component={'form'} onSubmit={handleSubmit}>
            <Typography><b>Date Range:</b></Typography>
                
            <Grid spacing={2} sx={{my:1}} container>
                <Grid xs={12} sm={6}>
                    <DatePicker
                        label="From"
                        inputFormat="MM/DD/YYYY"
                        required
                        value={formData.from}
                        onChange={(val)=>
                            setFormData({
                                ...formData,
                                from:moment(val).startOf('day')
                            })
                        }
                        renderInput={(params) => <TextField fullWidth size='small' required {...params} />}
                    />
                </Grid>
                <Grid xs={12} sm={6}>
                    <DatePicker
                        label="To"
                        inputFormat="MM/DD/YYYY"
                        required
                        value={formData.to}
                        onChange={(val)=>
                            setFormData({
                                ...formData,
                                to:moment(val).endOf('day')
                            })
                        }
                        renderInput={(params) => <TextField fullWidth size='small' required {...params} />}
                    />
                </Grid>
            </Grid>
            <Box display={'flex'} justifyContent={'end'} flexDirection={'row'}>
                <Button type='submit' variant='contained' disabled={!formData.from || !formData.to}>Confirm</Button>
            </Box>
        </Box>
    )
}


const ExportButton = ()=>{
    const [modalOpen, setModalOpen] = React.useState(false);

    const getCsvData = (data)=>{
        let csvData = [];
        csvData.push([
            'Date Approved', 'Date Added', 
            'Date Sold', 'Vendor', 
            'Receipt Number',
            'SKU', 'Item Name', 'Variant',
            'Recalled', 'Quantity',
            'Price', 'Tax%',
            'Tax', 'Encashment',
        ]);

        data.forEach(item=>{
            csvData.push([
                moment(item.dateApproved).format('LL'), moment(item.dateCreated).format('LL'),
                moment(item.dateSold).format('LL'), item.vendorUsername,
                item.receiptNumber,
                item.sku, item.name, item.variant,
                item.priceTotal > 0 ? 'No' : 'Yes', item.quantity,
                item.price, item.tax,
                (parseFloat(item.priceTotal) - parseFloat(item.priceTaxed)).toFixed(2),item.priceTaxed
            ])
        })
        return csvData;
    }

    return (
        <>
            <Button variant='contained' onClick={()=>setModalOpen(true)}>Export</Button>
            <ExportModal
                open={modalOpen}
                onClose={()=>setModalOpen(false)}
                title={"Export Sold Items"}
                path={'/api/sales/solditems'}
                fileName={'soldItems'}
                getCsvData={getCsvData}
            />
        </>
    )
}

export default ItemSoldTable;