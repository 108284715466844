import { Divider, Tab, Tabs } from "@mui/material"

const SoldTabs = ({tabValue, setTabValue})=>{

    return (
        <>
            <Tabs variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile value={tabValue}>
                <Tab label="All" value="all" onClick={(e)=>setTabValue('all')}/>
                <Tab label="Sold" value="sold" onClick={(e)=>setTabValue('sold')}/>
                <Tab label="Recalled" value="recalled" onClick={(e)=>setTabValue('recalled')}/>
            </Tabs>
            <Divider/>
        </>
    )
}

export default SoldTabs;