import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { FetchersContext } from "../../Contexts/FetchersContext";
import moment from "moment";
import LoadingOverlay from "../LoadingOverlay";
import { CSVLink } from "react-csv";
const defaultDateRange = {
    from:null, to:null
}
const ExportSales = ({dateRange=defaultDateRange})=>{
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <ExportModal open={open} onClose={()=>setOpen(false)} dateRange={dateRange}/>
            <Button variant={"contained"} onClick={()=>setOpen(true)}>
                Export Sales
            </Button>
        </>
    )
}

const ExportModal = ({open, onClose, dateRange})=>{
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [loading, setLoading] = React.useState(false);
    const [csvData, setCsvData] = React.useState([]);

    const link = React.useRef();
    const fileName = "sales"+moment(dateRange.from).format('YYYY-MM-DD')+'to'+moment(dateRange.to).format('YYYY-MM-DD')+'.csv';

    React.useEffect(()=>{
        if(!open) return;
        setLoading(true);
        fetchAuthed({
            path:'/api/sales/export?'+new URLSearchParams(dateRange).toString()
        },{
            succ:(data)=>{
                let newCsvData = [];
                newCsvData.push([
                    'Receipt Number', 'Sale Date',
                    'Total Price','Total Tax', 'Total Encashment',
                    'Cash Payment', 'E-Payment','Total Payment',
                    'Item SKU', 'Item Name', 'Item Variant', 'Item Qty', 
                    'Sub-Total Price', 'Sub-Total Tax', 'Sub-Total Encashment'
                ])
                data.result.forEach(receipt=>{
                    let newRows = [];
                    receipt.saleItems.forEach(saleItem=>{
                        let taxTotal = parseFloat(receipt.priceTotal) - parseFloat(receipt.priceTaxed);
                        let paymentTotal = parseFloat(receipt.cashPayment)+parseFloat(receipt.electronicPayment);

                        let subTotalTax = parseFloat(saleItem.priceTotal)-parseFloat(saleItem.priceTaxed);
                        if(newRows.length < 1){
                            newRows.push([
                                receipt.receiptNumber, moment(receipt.dateOfSale).format('LL'),
                                receipt.priceTotal, taxTotal, receipt.priceTaxed,
                                receipt.cashPayment, receipt.electronicPayment, paymentTotal,
                                saleItem.sku, saleItem.name, saleItem.variant, saleItem.quantity,
                                saleItem.priceTotal, subTotalTax, saleItem.priceTaxed
                            ])
                        }else{
                            newRows.push([
                                '','',
                                '','','',
                                '','','',
                                saleItem.sku, saleItem.name, saleItem.variant, saleItem.quantity,
                                saleItem.priceTotal, subTotalTax, saleItem.priceTaxed
                            ])
                        }
                    })
                    newCsvData.push(...newRows);
                })

                setCsvData(newCsvData);
            },
            final:()=>{
                setLoading(false);
            }
        })
    },[open, dateRange, fetchAuthed])

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Export Sales
            </DialogTitle>
            <DialogContent sx={{position:'relative'}}>
                <LoadingOverlay loading={loading}/>
                <Typography>
                    Export sales from {moment(dateRange.from).format('LL')} to {moment(dateRange.to).format('LL')}.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button disabled={loading} onClick={()=>link.current.link.click()}>Export</Button>
                <Box display={'none'}>
                    <CSVLink data={csvData} ref={link} filename={fileName}>Export</CSVLink>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ExportSales;