import React from "react";
import Box from '@mui/material/Box';
import OrderTable from "../../Components/Orders/OrderTable";
import moment from "moment";
import OrderDetailsModal from  '../../Components/Orders/OrderDetailsModal';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from "@mui/material";
import ItemsDetailsModal from "../Orders/ItemsDetailsModal";

const getColumns = (data={})=>{
    let columns = [{
        id: "saleId",
        label: "Sale ID",
        minWidth:50,
        sortable:true,
    },{
        id: "items",
        label: "Items",
        minWidth:50,
        sortable:true,
        getValue:(order)=>{
            let preview = '';
            if(order.items.length === 1){
                if(order.items[0].inventoryItem){
                    preview = order.items[0].inventoryItem.itemSKU;
                }else if(order.items[0].customItem){
                    preview = order.items[0].customItem.name;
                }
            }
            return (
                <Link href='#' onClick={()=>data.openItemsDetailsModal(order.items)}>
                    {order.items.length}{preview ? ': '+preview:''}
                </Link>
            )
        }
    },{
        id: "customer",
        label: "Customer",
        minWidth:50,
        sortable:true,
    },{
        id: "totalPrice",
        label: "Price",
        minWidth:30,
        sortable:true,
    },{
        id: "note",
        label: "Note",
        minWidth:50,
        sortable:true,
    },{
        id: 'dropLocation',
        label:'Drop Location',
        minWidth:60,
        getValue:(data)=> data.orderDrop?.dropLocation,
        sortable:true,
    },{
        id: 'handlingFee',
        label:'Handling Fee',
        minWidth:30,
        getValue:(data)=>data.orderDrop?.handlingFee,
        sortable:true,
    },{
        id: 'dropDate',
        label:'Drop Date',
        minWidth:60,
        getValue:(data)=>{
            let date = data.orderDrop?.dropDate;
            if(date) return moment(date).format('LL');
        },
        sortable:true,
    },{
        id:'claimDate',
        label:'Date Claimed',
        minWidth:60,
        format:(v)=>{
            if(v) return moment(v).format('LL');
            else return null;
        }
    },{
        id:'actions',
        label:'Actions',
        minWidth:140,
        width:140,
        actions:[{
            title:'Details',
            fn:(value)=>{
                data.openOrderDetailsModal(value);
            },
            icon:<MoreHorizIcon fontSize="inherit"/>
        }]
    }];

    return columns;
};

const ProfileDropTable = ({dataPath})=>{
    const [detailsOrder, setDetailsOrder] = React.useState({
        id:'',
        name:'',
        customer:'',
        price:'',
        note:'',
    });

    const [detailsItems, setDetailsItems] = React.useState(null);
    const openItemsDetailsModal = (items)=>{
        setDetailsItems([...items])
    }

    const openOrderDetailsModal = (order)=>{
        setDetailsOrder({
            ...order
        });
    }

    return (
        <Box>
            <OrderDetailsModal
                order={detailsOrder}
            />
            <OrderTable
                dataPath={dataPath}
                columns={getColumns({openItemsDetailsModal, openOrderDetailsModal})}
                searchOptions={['Customer', 'Note']}
            />
            <ItemsDetailsModal
                items={detailsItems}
            />
        </Box>
    );
}

export default ProfileDropTable;