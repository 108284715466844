import React from "react";
import Box from '@mui/material/Box';

import { FetchersContext } from "../../Contexts/FetchersContext";
import FormattedTable from "../FormattedTable";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { UserContext } from "../../Contexts/UserContext";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import moment from "moment";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel } from "@mui/material";

const getColumns = ()=>[
    {
        id: "username",
        label: "Username",
        minWidth:60,
    },{
        id: "name",
        label: "Name",
        minWidth:60,
    },{
        id: "vendorCode",
        label: "Vendor Code",
        minWidth:60
    },{
        id: "permissions",
        label: "Permissions",
        minWidth:60,
        getValue:(data)=>{
            return data.permissions;
        },
        /*format:(value)=>{
            if(!(value instanceof Array)) return '';
            let t = '';
            value.forEach((v)=>t+=v.name);
            return t;
        }*/
    },{
        id: "refID",
        label: "Referral ID",
        minWidth:60,
        getValue:(data)=>{
            return data.refId;
        }
    }
];

const getActionColumn = (user, data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:140,
        width:140,
        actions:[]
    };

    col.actions.push({
        title:'Details',
        fn:(value)=>{
            data.openUserDetailsModal(value);
        },
        icon:<MoreHorizIcon fontSize="inherit"/>
    });
    col.actions.push({
        title:'Remove',
        fn:(value)=>{
            data.openDeleteUserModal(value);
        },
        icon:<DeleteIcon fontSize="inherit"/>,
        isHidden:(value)=>user.username === value.username
    });
    return col;
}

const UserTable = function({display}){
    const {fetchAuthed} = React.useContext(FetchersContext);
    const {user} = React.useContext(UserContext);
    const [tableData, setTableData] = React.useState({
        columns:[],
        data:[],
        count:0
    });
    const [pagination, setPagination] = React.useState({
        page:0,
        rowsPerPage:10,
    });
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);

    const [deleteUser, setDeleteUser] = React.useState({});
    const [detailsUser, setDetailsUser] = React.useState({});

    const openDeleteUserModal = (user)=>{
        console.log(user);
        setDeleteUser({
            ...user
        });
    }

    const openUserDetailsModal = (user)=>{
        setDetailsUser({
            ...user
        });
    }

    const columns = React.useRef([
        ...getColumns(),
        getActionColumn(user,{openDeleteUserModal, openUserDetailsModal})
    ]);

    React.useEffect(()=>{
        setLoading(true);
        fetchAuthed({
            path:'/api/users?'+ new URLSearchParams({limit:pagination.rowsPerPage,offset:pagination.rowsPerPage*pagination.page}),
        },{
            succ:(data)=>{
                console.log(data);
                setTableData({
                    columns:columns.current,
                    data:data.result,
                    count:data.count
                });
            },
            final:()=>{
                setLoading(false);
            }    
        });
    },[fetchAuthed, pagination, refresh]);

    const mFormattedTable = React.useMemo(()=>(
        <FormattedTable 
            tableData={tableData} 
            pagination={pagination} 
            onChangePagination={(p)=>setPagination(p)} 
            loading={loading}
        />
    ),[loading, tableData, pagination]);

    return (
        <Box sx={{display:display?undefined:'none'}}>
            <DeleteUserModal
                user = {deleteUser}
                onDelete={()=>setRefresh(n=>n+1)}
            />
            <UserDetailsModal
                user={detailsUser}
                onEdit={()=>setRefresh(n=>n+1)}
                onSetStaff={()=>setRefresh(n=>n+1)}
            />
            {mFormattedTable}
        </Box>
    );
}

const DeleteUserModal = ({user, onDelete})=>{
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        password:''
    });

    const handleSubmit = (e)=>{
        e.preventDefault();
        fetchCSRF({
            succ:(csrf)=>{
                fetchAuthed({
                    path:'/api/users/'+user.id,
                    method:'delete',
                    headers:{
                        'x-csrf-token':csrf,
                        'confirmation-password':formData.password
                    },
                },{
                    succ:()=>{
                        onDelete();
                        setOpen(false);
                    },
                    succMessage:'Successfully deleted user'
                })
            }
        });
    }

    React.useEffect(()=>{
        if(user.id) {
            setFormData({
                password:''
            });
            setOpen(true);
        }
    },[user]);

    return(
        <Dialog
            fullWidth maxWidth='xs'
            open={open}
            onClose={()=>setOpen(false)}
            component='form'
            onSubmit={handleSubmit}
        >
            <DialogTitle>
                Delete user {user.username}?
            </DialogTitle>
            <DialogContent>
                <Alert severity='error'>Remove user?</Alert>
                <TextField size='small' type='password' required fullWidth sx={{mt:2}} label="Re-input your password" value={formData.password} onChange={(e)=>setFormData({password:e.target.value})}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Cancel</Button>
                <Button type='submit'>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}

const UserDetailsModal = ({user, onEdit, onSetStaff})=>{
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const [open, setOpen] = React.useState(false);
    const [form, setForm] = React.useState({
        name:'',
        password:'',
        password2:'',
        adminPassword:'',
        vendorCode:'',
        isStaff:false,
    })

    React.useEffect(()=>{
        if(user.id) {
            setForm(n=>({
                ...n,
                name:user.name || '',
                isStaff:Boolean(user.permissions.find(p=>p==='staff')),
                vendorCode: user.vendorCode || ''
            }));
            setOpen(true);
        }
    },[user]);

    const generateHandleSubmit = (mode)=>{
        return (e)=>{
            e.preventDefault();
            fetchCSRF({
                succ:(csrf)=>{
                    fetchAuthed({
                        path:'/api/users/'+user.id,
                        method:'put',
                        headers:{
                            'content-type':'application/json',
                            'x-csrf-token':csrf
                        },
                        body:JSON.stringify({
                            password: mode==='password' ? form.password : undefined,
                            name: mode === 'name' ? form.name : undefined,
                            vendorCode: mode === 'vendor code' ? form.vendorCode : undefined
                        })
                    },{
                        succ:(data)=>{
                            setForm(n=>({
                                ...n,
                                name:n.name,
                                password:'',
                                password2:'',
                                vendorCode:n.vendorCode
                            }));
                            onEdit();
                        },
                        succMessage:'Successfully changed '+mode
                    });
                }
            });
        }
    }

    const handleSetAsStaffSubmit = (e)=>{
        e.preventDefault();
        fetchCSRF({
            succ:(csrf)=>{
                fetchAuthed({
                    path:'/api/users/staff/'+user.id,
                    method:'PATCH', //needs to be capitalized for some reason :/
                    headers:{
                        'content-type':'application/json',
                        'x-csrf-token':csrf,
                        'confirmation-password':form.adminPassword
                    },
                    body:JSON.stringify({
                        isStaff:form.isStaff  
                    })
                },{
                    succ:()=>{
                        setForm(n=>({
                            ...n,
                            adminPassword:''
                        }))
                        onSetStaff();
                    },
                    succMessage:'Successfully set user as staff'
                })
            }
        })
    }

    return(
        <Dialog
            fullWidth maxWidth='sm'
            open={open}
            onClose={()=>setOpen(false)}
        >
            <DialogTitle>
                User Details for {user.username}
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid xs={12} sm={6}>
                        <Typography><b>ID:</b> {user.id}</Typography>
                        <Typography><b>Username:</b> {user.username}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Typography><b>Name:</b> {user.name}</Typography>
                        <Typography><b>Date Created:</b> {moment(user.createdAt).format('LL')}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{mt:2}}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Change Name
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} component='form' onSubmit={generateHandleSubmit('name')}>
                                <Grid xs={12} sm={8} md={10}>
                                    <TextField
                                        label='Name'
                                        size='small'
                                        fullWidth
                                        required
                                        value={form.name}
                                        onChange={(e)=>{
                                            setForm(n=>({
                                                ...n,
                                                name:e.target.value
                                            }));
                                        }}
                                    />
                                </Grid>
                                <Grid xs sm md>
                                    <Button type='submit' fullWidth variant='contained'>
                                        Change
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Change Vendor Code
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} component='form' onSubmit={generateHandleSubmit('vendor code')}>
                                <Grid xs={12} sm={8} md={10}>
                                    <TextField
                                        label='Vendor Code'
                                        size='small'
                                        fullWidth
                                        required
                                        value={form.vendorCode}
                                        onChange={(e)=>{
                                            setForm(n=>({
                                                ...n,
                                                vendorCode:e.target.value
                                            }));
                                        }}
                                    />
                                </Grid>
                                <Grid xs sm md>
                                    <Button type='submit' fullWidth variant='contained'>
                                        Change
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Reset User's Password
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} component='form' onSubmit={generateHandleSubmit('password')}>
                                <Grid xs={12} sm={8} md={10}>
                                    <Stack direction='column' spacing={2}>
                                        <TextField
                                            label='New Password'
                                            size='small'
                                            fullWidth
                                            required
                                            value={form.password}
                                            onChange={(e)=>{
                                                setForm(n=>({
                                                    ...n,
                                                    password:e.target.value
                                                }));
                                            }}
                                            type='password'
                                        />
                                        <TextField
                                            label='Input New Password Again'
                                            size='small'
                                            fullWidth
                                            required
                                            error={form.password !== form.password2}
                                            helperText={form.password === form.password2 ? '':'Passwords do not match'}
                                            value={form.password2}
                                            onChange={(e)=>{
                                                setForm(n=>({
                                                    ...n,
                                                    password2:e.target.value
                                                }));
                                            }}
                                            type='password'
                                        />
                                    </Stack>
                                </Grid>
                                <Grid xs sm md>
                                    <Button type='submit' fullWidth variant='contained'
                                        disabled={!form.password || form.password !== form.password2}
                                    >
                                        Change
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Set User as Staff
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} component='form' onSubmit={handleSetAsStaffSubmit}>
                                <Grid xs={12} sm={8} md={10}>
                                    <Stack direction='column' spacing={2}>
                                        <FormControlLabel
                                            label={'Is Staff'}
                                            control={
                                                <Checkbox
                                                    checked={form.isStaff}
                                                    onChange={(e)=>setForm(n=>{
                                                        return {
                                                            ...n,
                                                            isStaff: e.target.checked
                                                        }
                                                    })}
                                                />
                                            }
                                        />
                                        <TextField
                                            label='Input admin password'
                                            size='small'
                                            fullWidth
                                            required
                                            value={form.adminPassword}
                                            onChange={(e)=>{
                                                setForm(n=>({
                                                    ...n,
                                                    adminPassword:e.target.value
                                                }));
                                            }}
                                            type='password'
                                        />
                                    </Stack>
                                </Grid>
                                <Grid xs sm md>
                                    <Button type='submit' fullWidth variant='contained'
                                        disabled={!form.adminPassword}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
                <Button component={Link} to={'/users/'+user.id}>Go to user profile</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserTable;