import React from "react";
//import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Typography from '@mui/material/Typography';

import RouteData from "../RouteData";
import { UserContext } from "../../Contexts/UserContext";
import { Navigate } from "react-router-dom";
import Page from "../../Components/Page";
import { Box, Button, TextField } from "@mui/material";
import moment from "moment";
import FullTable from "../../Components/FullTable";
import ReceiptModal from "../../Components/Sales/ReceiptModal";
import SaleDetailsModal from "../../Components/Sales/SaleDetailsModal";
import { DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Unstable_Grid2';
import { FetchersContext } from "../../Contexts/FetchersContext";
import LoadingOverlay from "../../Components/LoadingOverlay";
import VendorSalesTable from "../../Components/Sales/VendorSalesTable";
import ExportSales from "../../Components/Sales/ExportSales";

const Report = ()=>{
    const {user} = React.useContext(UserContext);
    const [dateRange, onSetDateRange] = React.useState({
        from:moment().startOf('day'), to:moment().endOf('day')
    });

    return (
        user && (user.permissions.includes('admin') || user.permissions.includes('staff'))? 
        <Page>
            <Typography variant="h6" sx={{p:2}}>
                Sales Report
            </Typography>
            <DateRangeSelector onSelectRange={(dateRange)=>(onSetDateRange(dateRange))}/>
            <SalesReport dateRange={dateRange}/>
            <Typography textAlign={'center'} variant="h6">Receipts</Typography>
            <ReceiptsTable dateRange={dateRange}/>
            <Box p={2} mb={2} display={'flex'} flexDirection={'row'} justifyContent={'end'}>
                <ExportSales dateRange={dateRange}/>
            </Box>
        </Page>
        : user ? 
        <Typography>
            Access restricted.
        </Typography>
        :
        <Navigate to='/signin'/>
    );
}

const DateRangeSelector = ({onSelectRange})=>{
    const [formData, setFormData] = React.useState({
        from:moment().startOf('day'), to:moment().endOf('day')
    })

    const handleSubmit = function(e){
        e.preventDefault();
        onSelectRange(formData);
    }

    return (
        <Box sx={{py:1,px:2}} component={'form'} onSubmit={handleSubmit}>
            <Typography><b>Date Range:</b></Typography>
                
            <Grid spacing={2} sx={{my:1}} container>
                <Grid xs={12} sm={6}>
                    <DatePicker
                        label="From"
                        inputFormat="MM/DD/YYYY"
                        required
                        value={formData.from}
                        onChange={(val)=>
                            setFormData({
                                ...formData,
                                from:moment(val).startOf('day')
                            })
                        }
                        renderInput={(params) => <TextField fullWidth size='small' required {...params} />}
                    />
                </Grid>
                <Grid xs={12} sm={6}>
                    <DatePicker
                        label="To"
                        inputFormat="MM/DD/YYYY"
                        required
                        value={formData.to}
                        onChange={(val)=>
                            setFormData({
                                ...formData,
                                to:moment(val).endOf('day')
                            })
                        }
                        renderInput={(params) => <TextField fullWidth size='small' required {...params} />}
                    />
                </Grid>
            </Grid>
            <Box display={'flex'} justifyContent={'end'} flexDirection={'row'}>
                <Button type='submit' variant='contained'>Confirm</Button>
            </Box>
        </Box>
    )
}

const numberFormat = new Intl.NumberFormat('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
const SalesReport = ({dateRange})=>{
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [loading, setLoading] = React.useState(false);
    const [salesReport, setSalesReport] = React.useState({
        vendorSalesReports: [],
    })

    React.useEffect(()=>{
        setLoading(true);
        fetchAuthed({
            path:'/api/sales/report?'+new URLSearchParams(dateRange).toString()
        },{
            succ:(data)=>{
                setSalesReport(data.result);
            },
            final:()=>{
                setLoading(false);
            }
        })
    },[fetchAuthed, dateRange])

    return (
        <Box sx={{py:1}}>
            <LoadingOverlay loading={loading}/>
            <Grid container sx={{px:2}}>
                <Grid xs={12} sm={6}>
                    <Detail label='Sold Total' value={numberFormat.format(salesReport.soldTotal)}/>
                    <Detail label='Tax Total' value={numberFormat.format(salesReport.taxTotal)}/>
                    <Detail label='Taxed Total' value={numberFormat.format(salesReport.taxedTotal)}/>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Detail label='Cash Payment Total' value={numberFormat.format(
                        parseFloat(salesReport.soldTotal) - parseFloat(salesReport.electronicPaymentTotal)
                    )}/>
                    <Detail label='Electronic Payment Total' value={numberFormat.format(salesReport.electronicPaymentTotal)}/>
                </Grid>
            </Grid>
            <Box sx={{my:2}}>
                <Typography textAlign={'center'} variant="h6">
                    Vendors
                </Typography>
                <VendorSalesTable vendorSales={salesReport.vendorSalesReports}/>
            </Box>
        </Box>
    )
}
const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}

const getColumns = ()=>{
    const columns = [{
        id:"dateOfSale",
        label: "Sale Date",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateOfSale;
            if(date) return moment(date).format('ll');
        }
    },{
        id:'receiptNumber',
        label:'Receipt Number',
        minWidth:50
    },{
        id:'priceTotal',
        label:'Total Price',
        minWidth:50
    },{
        id:'handler',
        label:'Handler',
        minWidth:50,
        getValue:(v)=>{
            return v.handlerUsername
        }
    }];

    return columns;
};

const getActionColumn = (data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:140,
        width:140,
        actions:[]
    };
    col.actions.push({
        title:'Details',
        fn:(value)=>{
            data.openDetailsSaleModal(value);
        },
        icon:<MoreHorizIcon fontSize="inherit"/>
    });
    col.actions.push({
        title:'Receipt',
        fn:(value)=>{
            data.openReceiptSaleModal(value);
        },
        icon:<ReceiptIcon fontSize="inherit"/>
    });
    /*col.actions.push({
        title:'Remove',
        fn:(value)=>{
            data.openDeleteItemModal(value);
        },
        icon:<DeleteIcon fontSize="inherit"/>
    });*/
    return col;
}


const ReceiptsTable = function({dateRange}){
    const [refresh, ] = React.useState(0);
    const [receiptSale, setReceiptSale] = React.useState({saleItems:[]});
    const [detailsSale, setDetailsSale] = React.useState({saleItems:[]});

    const openReceiptSaleModal = (sale)=>{
        setReceiptSale({...sale});
    }

    const openDetailsSaleModal = (sale)=>{
        setDetailsSale({...sale});
    }

    const columns = React.useRef([
        ...getColumns(),
        getActionColumn({openReceiptSaleModal, openDetailsSaleModal})
    ])

    const mTable = React.useMemo(()=>(
        <FullTable
            columns={columns.current}
            dataPath = '/api/sales'
            refresh={refresh}
            disableSearch
            urlSearchParams={dateRange}
        />
    ),[refresh, dateRange]);

    return (
        <>
            <SaleDetailsModal sale={detailsSale}/>
            <ReceiptModal sale={receiptSale}/>
            {mTable}
        </>
    );
}

export const routeData = new RouteData({
    path:'/sales/report',
    element: <Report/>
},{
    name: 'Report',
    icon: <AssessmentIcon/>,
    isVisible: (user)=>{
        return user && (user.permissions.includes('admin') || user.permissions.includes('staff'));
    }
})