import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from '@mui/material/CircularProgress';
import { alpha } from "@mui/material";

import { FetchersContext } from '../../Contexts/FetchersContext';

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const ReferralLinkModal = ({modalOpen, onGenerate, handleCloseModal})=>{
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const [refID, setRefID] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [notifOpen, setNotifOpen] = React.useState(false);

    const refLinkRef = React.useRef(null);

    const saved = React.useRef(false);

    React.useEffect(()=>{
        if(modalOpen){
            setRefID('');
            saved.current = false;
        }
    },[modalOpen]);

    const refLink = window.location.origin+'/signup?'+new URLSearchParams({
        refID:refID
    });

    const generateID = ()=>{
        setRefID(makeid(32));
        saved.current = false;
    }

    const handleSubmit = (event)=>{
        event.preventDefault();
        setLoading(true);
        fetchCSRF({
            succ:(csrf)=>{
                fetchAuthed({
                    path:'/api/users/refid',
                    method:'post',
                    headers:{
                        'content-type':'application/json',
                        'x-csrf-token':csrf,
                    },
                    body:JSON.stringify({
                        refID
                    })
                },{
                    succ:(data)=>{
                        saved.current = true;
                        onGenerate();
                    },
                    succMessage:'Successfully registered referral ID',
                    final:()=>{
                        setLoading(false);
                    }
                });
            }
        });
    }

    return (
        <Dialog
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-add-modal-title"
            scroll="body"
            fullWidth maxWidth={'xs'}
            component='form'
            onSubmit={handleSubmit}
        >
            <Snackbar
                open={notifOpen}
                autoHideDuration={2000}
                onClose={(event)=>setNotifOpen(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                message="Copied to clipboard."
            />
            <DialogTitle>Referral Link</DialogTitle>
            <DialogContent dividers>
                {loading ? (
                    <Box sx={{ 
                        position: 'absolute', 
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0 ,
                        display: 'flex',
                        bgcolor:alpha('#000', 0.2),
                        justifyContent: 'center',
                        flexDirection: "column",
                        zIndex:2000
                    }}>
                        <CircularProgress sx={{mx:'auto'}}/>
                    </Box>
                ):''}
                <Button onClick={generateID} variant='contained'>Generate Referral ID</Button>
                <TextField sx={{width:'100%',mt:2}} label='Referral ID' value={refID}/>
                <TextField sx={{width:'100%',mt:2}} inputRef={(t)=>refLinkRef.current = t} label='Referral Link' value={refLink}/>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Close</Button>
                <Button disabled={saved.current || !Boolean(refID)} type='submit'>Save</Button>
                <Button disabled={!saved.current} onClick={(event)=>{
                    if(navigator.copyToClipboard)
                        navigator.copyToClipboard.writeText(refLink)
                    else{
                        refLinkRef.current.select();
                        document.execCommand('copy');
                        event.target.focus();
                    }
                    setNotifOpen(true);
                }}>Copy Link To Clipboard</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReferralLinkModal;