import React from 'react';
import { Logout } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import RouteData from "./RouteData";
import { UserContext } from '../Contexts/UserContext';
import { FetchersContext } from '../Contexts/FetchersContext';

const Signout = ()=>{
    const {user,setUser} = React.useContext(UserContext);
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const navigate = useNavigate();

    React.useEffect(()=>{
        if(!user) return navigate('/',{replace:true});
        fetchCSRF({succ:csrf=>{
            fetchAuthed({
                path:'/api/auth/signout',
                method:'post',
                headers:{
                    'x-csrf-token':csrf
                },
            },{
                succ:()=>{
                    setUser(null);
                    navigate('/',{replace:true});
                },
            });
        }});
    },[navigate, setUser, user, fetchCSRF, fetchAuthed]);

    return (
        <Box 
            display="flex" 
            justifyContent="center"
            alignItems="center"
            sx={{my:5,}}>
            <CircularProgress/>
        </Box>
    )
};
export default Signout
export const routeData = new RouteData({
    path:'/signout',
    element:<Signout/>
},{
    name:'Sign Out',
    icon:<Logout/>,
    isVisible:(user)=>user
})