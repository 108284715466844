import React from 'react';

import { Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, alpha} from '@mui/material';
import SubmitModal from '../../Components/SubmitModal';
import ReceiptModal from '../Sales/ReceiptModal';

const RecallButton = function({itemQuantities:itemQuantitiesInput, onRecall, disabled}){
    const [itemQuantities, setItemQuantities] = React.useState([]);
    const clickHandler = (e)=>{
        e.preventDefault();
        setItemQuantities([
            ...itemQuantitiesInput
        ]);
    }
    return (
        <>
            <Button variant='contained' disabled={disabled || itemQuantitiesInput.length<1} color='warning' onClick={clickHandler} >Recall</Button>
            <RecallFormModal itemQuantities={itemQuantities} onSubmit={onRecall}/>
        </>
    )
}

const defaultFormData = {
    items:[]
};
const RecallFormModal = function({itemQuantities, onSubmit}){
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({...defaultFormData});
    const [submitted, setSubmitted] = React.useState(false);
    const [submittedSale, setSubmittedSale] = React.useState({saleItems:[]}); //for caching
    const [modalSale, setModalSale] = React.useState({saleItems:[]}); //for displaying to modal

    const openReceiptModal = function(sale){
        setModalSale({...sale});
    }

    React.useEffect(()=>{
        if(itemQuantities.length > 0){
            setOpen(true);
            setSubmitted(false);
            setModalSale({saleItems:[]});
            setFormData(n=>{
                let formItems = [];
                itemQuantities.forEach((itemQuantity)=>{
                    if(formItems.findIndex(i=>i.id === itemQuantity.item.id) < 0){
                        formItems.push({
                            id:itemQuantity.item.id,
                            quantity:itemQuantity.quantity,
                            subTotal:itemQuantity.subTotal
                        })
                    }
                });

                return {
                    ...defaultFormData,
                    items:formItems
                };
            })
        }
    },[itemQuantities])

    return (
        <SubmitModal
            title='Recall Items'
            modalOpen={open}
            submitPath={'/api/sales/recall'}
            submitMethod={'post'}
            getSubmitBody={()=>JSON.stringify(formData)}
            onCloseModal={()=>setOpen(false)}
            maxWidth={'md'}
            cancelTitle='Close'
            canSubmit={!submitted}
            additionalButtons={
                <Button disabled={!submitted} onClick={()=>{openReceiptModal(submittedSale)}}>
                    Print Receipt
                </Button>
            }
            onSubmit={(data)=>{
                console.log(data);
                onSubmit();
                setSubmitted(true);
                setSubmittedSale(data.result);
            }}
            contentPadding={'8px'}
            closeModalOnSubmit={false}
        >
            <ReceiptModal sale={modalSale}/>
            <Box position='relative'>
                {submitted ? 
                    <Box sx={{ 
                        position: 'absolute', 
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0 ,
                        display: 'flex',
                        bgcolor:alpha('#000', 0.05),
                        justifyContent: 'center',
                        flexDirection: "column",
                        zIndex:2000
                    }}/>
                : '' }
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{minWidth:30}}>
                                    SKU
                                </TableCell>
                                <TableCell sx={{minWidth:20}}>
                                    Price
                                </TableCell>
                                <TableCell sx={{minWidth:20}}>
                                    Stock
                                </TableCell>
                                <TableCell sx={{minWidth:120}} width={120}>
                                    Quantity
                                </TableCell>
                                <TableCell sx={{minWidth:30}}>
                                    Subtotal
                                </TableCell>
                                <TableCell sx={{minWidth:30}}>
                                    Item Name
                                </TableCell>
                                <TableCell sx={{minWidth:30}}>
                                    Variant
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                itemQuantities.map((itemQuantity, i)=>{
                                    let item = itemQuantity.item;
                                    if(!item) return '';
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell >
                                                {item.sku}
                                            </TableCell>
                                            <TableCell>
                                                {item.price}
                                            </TableCell>
                                            <TableCell>
                                                {item.itemStock.stock}
                                            </TableCell>
                                            <TableCell>
                                                {itemQuantity.quantity}
                                            </TableCell>
                                            <TableCell>
                                                0
                                            </TableCell>
                                            <TableCell>
                                                {item.name}
                                            </TableCell>
                                            <TableCell>
                                                {item.variant}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </SubmitModal>
    )
}

export default RecallButton;