import React from 'react';
import FormattedTable from "./FormattedTable";
import SearchBar from './SearchBar';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

//import { UserContext } from '../Contexts/UserContext';
import { FetchersContext } from '../Contexts/FetchersContext';

const emptyJSON = {};
const defaultSearchOptions = [];
const defGetRowId = (row)=>row.id;
const FullTable = function({dataPath, urlSearchParams=emptyJSON, columns, disableSearch=false,leftHeading=null, searchOptions=defaultSearchOptions, refresh = 0, getRowId = defGetRowId}){
    //const {user} = React.useContext(UserContext);
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [pagination, setPagination] = React.useState({
        page:0,
        rowsPerPage:10
    });
    const [loading, setLoading] = React.useState(false);
    const [tableData, setTableData] = React.useState({
        columns,
        data: [],
        count:0,
    });
    const [searchQuery, setSearchQuery] = React.useState({
        column: null,
        query:null
    });
    const [sortInfo, setSortInfo] = React.useState({
        column:'',
        direction:'asc'
    });

    const [searchParams, setSearchParams] = React.useState({ //grouped so as to update only after both are set
        pagination, searchQuery
    });

    const firstRender = React.useRef(true);

    React.useEffect(()=>{
        setTableData(n=>({
            ...n,
            columns
        }));
    },[columns]);

    React.useEffect(()=>{
        if(firstRender.current) { //prevent rerender
            firstRender.current=false;
            return;
        }
        setSearchParams(n=>{
            if(n.searchQuery === searchQuery){
                return {
                    pagination, searchQuery
                }
            }else{
                console.log('search params is changed');
                return {
                    pagination:{...pagination,page:0}, searchQuery
                }
            }
        });
    },[pagination,searchQuery]);

    React.useEffect(()=>{
        setLoading(true);
        console.time('doSomething');
        let params = {
            limit:searchParams.pagination.rowsPerPage,
            offset:searchParams.pagination.rowsPerPage*searchParams.pagination.page,
            ...urlSearchParams
        };
        if(sortInfo.direction && sortInfo.column){
            params.direction = sortInfo.direction;
            params.orderby = sortInfo.column;
        }
        if(searchParams.searchQuery && searchParams.searchQuery.query && searchParams.searchQuery.column){
            params.searchcolumn = searchParams.searchQuery.column;
            params.searchquery = searchParams.searchQuery.query;
        }
        let path = dataPath+'?'+new URLSearchParams(params);

        fetchAuthed({
            path,
        },{
            succ:(data)=>{
                console.timeEnd('doSomething')
                console.log(data);
                setTableData(n=>({
                    ...n,
                    data:data.result,
                    count:data.count,
                }));
            },
            final:()=>{
                setLoading(false);
            }    
        })
    },[fetchAuthed, searchParams, refresh, /*user,*/ dataPath, sortInfo, urlSearchParams]);

    const mFormattedTable = React.useMemo(()=>(
        <FormattedTable 
            pagination={searchParams.pagination}
            onChangePagination={setPagination}
            tableData={tableData}
            loading={loading}
            onSort={setSortInfo}
            sortInfo={sortInfo}
            getRowId={getRowId}
        />
    ), [tableData, loading, searchParams, sortInfo, getRowId]);

    return (
        <>
            { disableSearch ? '':
                <Box sx={{p:2}}>
                    <Grid container  spacing={2}>
                        <Grid xs={12} sm={6}>
                            {leftHeading}
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <SearchBar
                                searchOptions={searchOptions}
                                onSearch={setSearchQuery}
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
            {mFormattedTable}
        </>
    );
}

export default FullTable;