import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Typography } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext"
import CoreqsApprovalTable from '../../Components/Cashout Requests/CoreqsApprovalTable';
import AdminCoreqsApprovalTable from '../../Components/Cashout Requests/AdminCoreqsApprovalTable';

const CashOutRequestApproval = function(){
    const {user} = React.useContext(UserContext);

    return user ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Cashout Request Approval
            </Typography>
            { user.permissions.includes('admin') ? <AdminCoreqsApprovalTable/>:<CoreqsApprovalTable/> }
        </Page>
        
    )
    : <Navigate to="/signin" />;
}

export default CashOutRequestApproval;
export const routeData = new RouteData({
    path:"/coreqs/approval",
    element:<CashOutRequestApproval />,
},{
    name:"For Approval",
    icon:<CheckCircle />,
    isVisible:(user)=>Boolean(user) && !user.permissions.includes('staff'),
});