
import Typography from '@mui/material/Typography';
import React from 'react';
import {Card, CardMedia, CardContent, CardActionArea} from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
const emptyJSON = {};
const AnnouncementMainCard = ({ announcementData=emptyJSON, sx=emptyJSON})=>{

    return(
        <Card sx={sx}>
            <CardActionArea component={Link} to={"/announcements/"+announcementData.id}>
                {announcementData.imgName ? 
                    <CardMedia
                        sx={{ 
                            height: {
                                xs:200,
                                sm:250,
                                md:300,
                            }
                        }}
                        image={'/api/announcements/image/'+announcementData.imgName}
                        title={announcementData.title}
                    />
                : null }
                <CardContent>
                    <Typography gutterBottom variant="h6" sx={{maxHeight:'2lh', overflow:'hidden', textOverflow:'ellipsis'}}>
                        {announcementData.title}
                    </Typography>
                    <Typography variant="body2" whiteSpace='pre-wrap' color="text.secondary" gutterBottom sx={{maxHeight:'4lh', overflow:'hidden', textOverflow:'ellipsis'}}>
                        {announcementData.text}
                    </Typography>
                    <Typography variant='body2' align='right' >
                        {moment(announcementData.dateCreated).format('ll')}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default AnnouncementMainCard;