export default class RouteData {
    constructor({path, element, action, loader, children}, {noTab=false, name, icon, isVisible, inPath, notifSource=null}={}){
        this.path = path;
        this.element = element;
        this.action = action;
        this.loader = loader;
        this.children = children;
        this.name = name;
        this.icon = icon;
        this.isVisible = isVisible ? isVisible : ()=>true;
        this.inPath = inPath ? inPath : ()=>false;
        this.noTab = noTab;
        this.notifSource = notifSource;
    }
}