import React from "react";
import SubmitModal from "../SubmitModal";
import AnnouncementForm from "./AnnouncementForm";

const AddAnnouncementModal = ({modalOpen, onCloseModal, onAdd})=>{
    const [formData,setFormData] = React.useState({
        title:'',
        text:'',
        imgName:'',
    });
    const [img, setImg] = React.useState(null);

    const getSubmitBody = ()=>{
        const submitFormData = new FormData();
        submitFormData.append('title', formData.title);
        submitFormData.append('text', formData.text);
        submitFormData.append('img', img);
        return submitFormData;
    }

    const handleSubmit = ()=>{
        setFormData({
            title:'',
            text:"",
            imgName:'',
        });
        onAdd();
    }

    return (
        <SubmitModal
            modalOpen={modalOpen}
            onCloseModal={onCloseModal}
            submitMethod='post'
            submitContentType=""
            title='Add Announcement'
            submitPath='/api/announcements'
            submitLabel='Add'
            succSubmitMessage="Successfully added announcement"
            onSubmit={handleSubmit}
            maxWidth='sm'
            getSubmitBody={getSubmitBody}
        >
            <AnnouncementForm img={img} formData={formData} onChangeForm={setFormData} onChangeImg={setImg}/>
        </SubmitModal>
    );
}

export default AddAnnouncementModal;