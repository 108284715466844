import { alpha, Box, CircularProgress } from "@mui/material";

const LoadingOverlay = ({loading = false})=>{
    return loading ? (
        <Box sx={{ 
            position: 'absolute', 
            top: 0,
            left: 0,
            bottom: 0,
            right: 0 ,
            display: 'flex',
            bgcolor:alpha('#000', 0.05),
            justifyContent: 'center',
            flexDirection: "column",
            zIndex:2000
        }}>
            <CircularProgress sx={{mx:'auto'}}/>
        </Box>
    ) : null;
}

export default LoadingOverlay;

