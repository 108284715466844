import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React from "react";
import LoadingOverlay from "./LoadingOverlay";
import { CSVLink } from "react-csv";
import moment from "moment";
import { DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Unstable_Grid2';
import { FetchersContext } from "../Contexts/FetchersContext";

const ExportModal = ({open, onClose, getCsvData, fileName, path, title})=>{
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [loading, setLoading] = React.useState(false);
    const [csvData, setCsvData] = React.useState(null);
    const [formData, setFormData] = React.useState({
        from:moment().startOf('day'), to:moment().endOf('day')
    })

    const link = React.useRef();
    const finalFileName = fileName+moment(formData.from).format('YYYY-MM-DD')+'to'+moment(formData.to).format('YYYY-MM-DD')+'.csv';

    const handleSubmit = (e)=>{
        e.preventDefault();
        setLoading(true);
        setCsvData(null);
        fetchAuthed({
            path:path+'?'+new URLSearchParams(formData).toString()
        },{
            succ:(data)=>{
                let raw = getCsvData(data.result);
                if(!raw) return;
                raw.forEach(row=>{
                    row.forEach((value,index)=>{
                        if(typeof value === "string")
                        row[index] = value.replace(/"/g, '""');
                    })
                })
                setCsvData(raw);
            },
            final:()=>{
                setLoading(false);
            }
        });
    }

    React.useEffect(()=>{
        if(!csvData) return;
        link.current.link.click();
    },[csvData])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            component={'form'}
            onSubmit={handleSubmit}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent sx={{position:'relative'}}>
                <LoadingOverlay loading={loading}/>
                <Typography>
                    Select date range:
                </Typography>
                <Grid spacing={2} sx={{my:1}} container>
                    <Grid xs={12} sm={6}>
                        <DatePicker
                            label="From"
                            inputFormat="MM/DD/YYYY"
                            required
                            value={formData.from}
                            onChange={(val)=>
                                setFormData({
                                    ...formData,
                                    from:moment(val).startOf('day')
                                })
                            }
                            renderInput={(params) => <TextField fullWidth size='small' required {...params} />}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <DatePicker
                            label="To"
                            inputFormat="MM/DD/YYYY"
                            required
                            value={formData.to}
                            onChange={(val)=>
                                setFormData({
                                    ...formData,
                                    to:moment(val).endOf('day')
                                })
                            }
                            renderInput={(params) => <TextField fullWidth size='small' required {...params} />}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button type='submit' disabled={loading || !formData.from || !formData.to}>Export</Button>
                <Box display={'none'}>
                    <CSVLink data={csvData ?? []} ref={link} filename={finalFileName}>Export</CSVLink>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default ExportModal;