import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const SearchBar = ({searchOptions, onSearch})=>{
    const [input, setInput] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(0);

    const optionsOpen = Boolean(anchorEl);

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = (event)=>{
        event.preventDefault();
        onSearch({
            column: searchOptions[selectedOptionIndex],
            query: input
        });
    }

    return(
        <Box
            display="flex"
            justifyContent="flex-end"
            component="form"
            onSubmit={handleSubmit}
        >
            <Menu
                open={optionsOpen}
                anchorEl={anchorEl}
                onClose={()=>setAnchorEl(null)}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
            >
                {searchOptions.map((option, index) => (
                    <MenuItem
                        key={option}
                        selected={index === selectedOptionIndex}
                        onClick={(event)=>{
                            setAnchorEl(null);
                            setSelectedOptionIndex(index);
                        }}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            <IconButton sx={{mr:1}} onClick={(e)=>setAnchorEl(e.currentTarget)}>
                <MenuIcon/>
            </IconButton>
            <TextField
                fullWidth
                label={"Search "+searchOptions[selectedOptionIndex]}
                variant="outlined"
                value={input}
                size='small'
                onChange={handleChange}
                InputProps={{
                    endAdornment:
                    <InputAdornment position="end">
                        <IconButton size="small" edge="end" type="submit">
                            <SearchIcon/>
                        </IconButton>
                    </InputAdornment>,             
                    style: {
                        paddingRight: 8
                    }
                }}
            />
        </Box>
    )
}

export default SearchBar;