import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import { alpha } from "@mui/material";

const defGetRowId = (row)=>row.id;
const FormattedTable = ({paginationActive = true, tableData={columns:[],data:[], count:0}, sortInfo, onChangePagination, pagination={page:0, rowsPerPage:10}, loading=false, onSort, getRowId=defGetRowId})=>{
    console.log('table rendered');
    const handleChangePage = (event, newPage) => {
        let newPagination={
            ...pagination,
            page:newPage,
        };
        onChangePagination(newPagination);
    };

    const handleChangeRowsPerPage = (event) => {
        let newPagination={
            ...pagination,
            page:0,
            rowsPerPage:event.target.value,
        };
        onChangePagination(newPagination);
    };

    const handleSort = (sortInfo)=>{
        onSort(sortInfo);
    }

    return (
        <Box sx={{position:'relative'}}>
            {loading ? (
                <Box sx={{ 
                    position: 'absolute', 
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0 ,
                    display: 'flex',
                    bgcolor:alpha('#000', 0.05),
                    justifyContent: 'center',
                    flexDirection: "column",
                    zIndex:2000
                }}>
                    <CircularProgress sx={{mx:'auto'}}/>
                </Box>
            ):''}
            { paginationActive ? <>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.count}
                    rowsPerPage={pagination.rowsPerPage}
                    page={pagination.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Divider /></>
            : null }
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {tableData.columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, width: column.width, padding:'8px 16px', backgroundColor:'transparent', maxWidth: column.maxWidth}}
                                >
                                    {column.sortable ? 
                                        <TableSortLabel
                                            active={sortInfo.column === column.id}
                                            direction={sortInfo.column === column.id ? sortInfo.direction:'asc'}
                                            onClick={()=>{
                                                handleSort({
                                                    column:column.id,
                                                    direction:sortInfo.column === column.id && sortInfo.direction === 'asc' ? 'desc':'asc'
                                                })
                                            }}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    : column.label }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.data
                        .map((row)=>{
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={getRowId(row)}
                                >
                                    {tableData.columns.map((column) => {
                                        let value = column.getValue ? column.getValue(row) : row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                            >
                                                <Box sx={{maxWidth: column.maxWidth}}>
                                                    {(()=>{
                                                        if(column.id==='actions'){
                                                            return column.actions.map((action,index)=>{
                                                                return action.isHidden && action.isHidden(row) ? null : (
                                                                    <Tooltip title={action.title} key={index} disableInteractive>
                                                                        <IconButton sx={{p:'4px'}} onClick={(event)=>action.fn(row)}>
                                                                            {action.icon}
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                );
                                                            });
                                                            
                                                        }else{
                                                            return column.format 
                                                                ? column.format(value)
                                                                : value;
                                                        }
                                                    })()}
                                                </Box>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            { paginationActive ? 
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={tableData.count}
                    rowsPerPage={pagination.rowsPerPage}
                    page={pagination.page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            : null }
        </Box>
    );
}

export default FormattedTable;