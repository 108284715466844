import React from 'react';
import Page from '../../Components/Page';
import RouteData from '../RouteData';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import Typography from '@mui/material/Typography';
import { UserContext } from '../../Contexts/UserContext';
import { Divider } from '@mui/material';
import { Navigate } from 'react-router-dom';
import AnnouncementList from '../../Components/Announcements/AnnouncementList';

const Announcements = ()=>{
    const {user} = React.useContext(UserContext);
    return(
        <Page>
            {user && user.permissions.includes('admin') ? <>
                <Typography variant='h6' sx={{p:2}}>
                    Announcements
                </Typography>
                <AnnouncementList />
                <Divider/>
            </> : user ?
                <Typography> Forbidden </Typography>
            :
                <Navigate to='/signin'/>
            }
        </Page>
    );
}

export const routeData = new RouteData({
    path:'/announcements',
    element:<Announcements/>
},{
    name:'Announcements',
    icon: <AnnouncementIcon/>,
    isVisible: (user)=>user && user.permissions.includes('admin')
});