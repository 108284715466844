import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

import FullTable from "../FullTable";
import { Alert, Stack, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import SubmitModal from "../SubmitModal";
import moment from "moment";
import ItemDetailsModal from "./ItemDetailsModal";

const getColumns = ()=>{
    const columns = [{
        id:"dateCreated",
        label: "Date Added",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            let date = v.dateCreated;
            if(date) return moment(date).format('ll');
        }
    },{
        id:'vendorUsername',
        label:"Vendor",
        minWidth:30,
        sortable:true,
        getValue:(v)=>{
            return v.vendor?.username
        }
    },{
        id:'sku',
        label:'SKU',
        minWidth:50,
        sortable:true
    },{
        id: "name",
        label: "Item Name",
        minWidth:60,
        sortable:true,
    },{
        id: "variant",
        label: "Variant",
        minWidth:60,
        sortable:true,
    },{
        id: "description",
        label: "Description",
        minWidth:60,
        sortable:true,
    },{
        id: "quantity",
        label: "Quantity",
        minWidth:30,
        sortable:true,
    },{
        id: "notes",
        label: "Notes",
        minWidth:60,
        sortable:true,
    }];

    return columns;
};

const getActionColumn = (data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:140,
        width:140,
        actions:[]
    };
    col.actions.push({
        title:'Details',
        fn:(value)=>{
            data.openDetailsItemModal(value);
        },
        icon:<MoreHorizIcon fontSize="inherit"/>
    });
    col.actions.push({
        title:'Edit',
        fn:(value)=>{
            data.openEditItemModal(value)
        },
        icon:<EditIcon fontSize="inherit"/>
    });

    col.actions.push({
        title:'Approve',
        fn:(value)=>{
            data.openApproveItemModal(value);
        },
        icon:<CheckIcon fontSize="inherit"/>
    });
    col.actions.push({
        title:'Remove',
        fn:(value)=>{
            data.openDeleteItemModal(value);
        },
        icon:<DeleteIcon fontSize="inherit"/>
    });
    return col;
}


const AdminItemApprovalTable = function(){
    const [refresh, setRefresh] = React.useState(0);
    const [deleteItem, setDeleteItem] = React.useState({name:''});
    const [editItem, setEditItem] = React.useState({});
    const [approveItem, setApproveItem] = React.useState({});
    const [detailsItem, setDetailsItem] = React.useState({});

    const openDetailsItemModal = (item)=>{
        setDetailsItem({
            ...item
        });
    }

    const openDeleteItemModal = (item)=>{
        setDeleteItem({
            ...item
        });
    }

    const openEditItemModal = (item)=>{
        setEditItem({
            ...item
        })
    }

    const openApproveItemModal = (item)=>{
        setApproveItem({...item});
    }
    
    const columns = React.useRef([
        ...getColumns(),
        getActionColumn({ openDeleteItemModal,  openEditItemModal, openDetailsItemModal, openApproveItemModal})
    ])

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            columns={columns.current}
            dataPath = '/api/items/approval/admin'
            refresh={refresh}
            searchOptions={['SKU','Item Name', 'Variant', 'Description', 'Vendor']}
        />
    ),[refresh]);

    return (
        <>
            <EditItemModal
                item={editItem}
                onEdit={()=>setRefresh(n=>n+1)}
            />
            <DeleteItemModal
                onDelete={()=>setRefresh(n=>n+1)}
                item={deleteItem}
            />
            <ItemDetailsModal
                item={detailsItem}
            />
            <ApproveItemModal item={approveItem} onApprove={()=>setRefresh(n=>n+1)}/>
            {mOrderTable}
        </>
    );
}

const EditItemModal = ({onEdit, item})=>{
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        sku:'',notes:''
    });

    React.useEffect(()=>{
        if(item.id) {
            setFormData({
                sku:item.sku,
                notes: item.notes ?? ''
            });
            setOpen(true);
        }
    },[item]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully edited item."
            submitMethod="put"
            submitLabel="Save"
            submitPath={'/api/items/approval/admin/'+item.id}
            onSubmit={onEdit}
            title='Edit Item'
            formData = {formData}
            onFormChange = {(val)=>setFormData(val)}
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
            getSubmitBody = {()=>{
                return JSON.stringify(formData);
            }}
        >
            <Grid container spacing={3}>
                <Grid 
                    sx={{
                        display:"flex",
                        flexDirection:"column",
                    }}
                    xs={12}
                    sm={12}
                >
                    <TextField variant="standard" sx={{mt:1}}
                        value={formData.sku} 
                        required
                        onChange={(event)=>setFormData({
                            ...formData, 
                            sku:event.target.value
                        })} 
                        label="SKU"
                    />
                    <TextField variant="outlined" sx={{mt:3}}
                        value={formData.notes} 
                        onChange={(event)=>setFormData({...formData, notes:event.target.value})} 
                        multiline rows={4} label="Notes" 
                    />
                </Grid>
            </Grid>
        </SubmitModal>
    );
}

const ApproveItemModal = ({onApprove, item})=>{
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        location:'',
        tax:''
    });

    React.useEffect(()=>{
        if(item.id) {
            setOpen(true);
        }
    },[item]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully approved item."
            submitMethod="put"
            submitLabel="Approve"
            submitPath={'/api/items/approval/approve/'+item.id}
            onSubmit={onApprove}
            title='Approve Item'
            formData = {formData}
            onFormChange = {setFormData}
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
            getSubmitBody={()=>{
                return JSON.stringify(formData);
            }}
        >
            <Grid container>
                <Grid xs={12} sm={6}>
                    <Detail label='SKU' value={item?.sku}/>
                    <Detail label='Item Name' value={item?.name}/>
                    <Detail label='Variant' value={item?.variant}/>
                    <Detail label='Description' value={item?.description}/>
                    <Detail label='Notes' value={item?.notes}/>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Detail label='Vendor' value={item?.vendor?.username}/>
                    <Detail label='Date Added' value={item?.dateCreated ? moment(item.dateCreated).format('ll'): ''}/>
                    <Detail label='Quantity' value={item?.quantity}/>
                    <Detail label='Price' value={item?.price}/>
                </Grid>
            </Grid>
            <Stack direction={'column'}>
                <TextField variant="standard" sx={{mt:1}}
                    value={formData.location} 
                    required
                    onChange={(event)=>setFormData({
                        ...formData, 
                        location:event.target.value
                    })} 
                    label="Location"
                />
                <TextField variant="standard" sx={{mt:1}}
                    value={formData.tax} 
                    required type='number' 
                    onWheel={(e) => {
                        e.target.blur(); 
                        setTimeout(() => {
                            e.target.focus()
                        }, 0);
                    }}
                    onChange={(event)=>setFormData({
                        ...formData, 
                        tax: event.target.value
                    })} 
                    inputProps={{min:0.00, step:0.01}}
                    label="Tax"
                    helperText="In percentage (e.g. 12 NOT 0.12)"
                />
            </Stack>
        </SubmitModal>
    );
}
const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}

const DeleteItemModal = ({onDelete, item})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(item.id) {
            setOpen(true);
        }
    },[item]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully deleted item."
            submitMethod="delete"
            submitLabel="Delete"
            submitPath={'/api/items/approval/admin/'+item.id}
            onSubmit={onDelete}
            title='Delete Item'
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
        >
            <Alert severity="warning">Delete item {item.name}?</Alert>
        </SubmitModal>
    );
}

export default AdminItemApprovalTable;