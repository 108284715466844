import ReactLink from '@mui/material/Link'
import { Link } from "react-router-dom";

const PageLink = ({children, path})=>{
    return (
        <ReactLink underline="hover" component={Link} to={path}>
            {children}
        </ReactLink>
    )
}

export default PageLink;