import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Typography } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext"
import AdminItemInventoryTable from '../../Components/VendorItems/AdminItemInventoryTable';
import ItemInventoryTable from '../../Components/VendorItems/ItemInventoryTable';

const VendorItemsInventory = function(){
    const {user} = React.useContext(UserContext);

    return user ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Vendor Items Inventory
            </Typography>
            { (user.permissions.includes('admin') || user.permissions.includes('staff')) ? <AdminItemInventoryTable/>:<ItemInventoryTable/> }
        </Page>
        
    )
    : <Navigate to="/signin" />;
}

export default VendorItemsInventory;
export const routeData = new RouteData({
    path:"/vendoritems/inventory",
    element:<VendorItemsInventory />,
},{
    name:"Inventory",
    icon:<ListAltIcon />,
    isVisible:(user)=>user,
});