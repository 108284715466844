import React from "react";
import SubmitModal from "../SubmitModal";
import AnnouncementForm from "./AnnouncementForm";

const EditAnnouncementModal = ({announcement, onEdit})=>{
    const [open, setOpen] = React.useState(false);
    const [formData,setFormData] = React.useState({
        title:announcement.title,
        text:announcement.text,
        imgName:announcement.imgName,
    });
    const [img, setImg] = React.useState(null);

    React.useEffect(()=>{
        if(announcement.id) {
            setFormData(announcement);
            setOpen(true);
        }
    },[announcement]);

    const getSubmitBody = ()=>{
        const submitFormData = new FormData();
        submitFormData.append('title', formData.title);
        submitFormData.append('text', formData.text);
        submitFormData.append('imgName', formData.imgName ? formData.imgName : '');
        submitFormData.append('img', img);
        for(var pair of submitFormData.entries()) {
            console.log(pair[0]+ ', '+ pair[1]); 
         }
        return submitFormData;
    }

    return (
        <SubmitModal
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
            submitMethod='put'
            submitContentType=""
            title='Edit Announcement'
            submitPath={'/api/announcements/'+announcement.id}
            submitLabel='Edit'
            succSubmitMessage="Successfully edited announcement"
            onSubmit={onEdit}
            maxWidth='sm'
            getSubmitBody={getSubmitBody}
        >
            <AnnouncementForm img={img} formData={formData} onChangeForm={setFormData} onChangeImg={setImg}/>
        </SubmitModal>
    );
}

export default EditAnnouncementModal;