import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';

import moment from "moment";
import FullTable from "../FullTable";
import SubmitModal from "../SubmitModal";
import { Box, Button, TextField, Typography } from "@mui/material";
import Wallet from "./Wallet";

const getColumns = ()=>{
    const columns = [{
        id: "dateRequested",
        label: "Date Requested",
        minWidth:60,
        sortable:true,
        getValue:(coreq)=>{
            return moment(coreq.dateRequested).format('LL')
        }
    },{
        id: "amount",
        label: "Amount",
        minWidth:30,
        getValue:(coreq)=>{
            return coreq.value;
        }
    }];
    return columns;
};

const getActionColumn = (data={})=>{
    let col = {
        id:'actions',
        label:'Actions',
        minWidth:140,
        width:140,
        actions:[]
    };
    col.actions.push({
        title:'Remove',
        fn:(value)=>{
            data.openDeleteModal(value);
        },
        icon:<DeleteIcon fontSize="inherit"/>
    });
    return col;
}

const CoreqsApprovalTable = function(){
    const [refresh, setRefresh] = React.useState(0);
    const [deleteCoreq, setDeleteCoreq] = React.useState({});
    const [walletData, setWalletData] = React.useState({balance:0});

    const openDeleteModal = (coreq)=>{
        setDeleteCoreq({
            ...coreq
        })
    }
    
    const columns = React.useRef([
        ...getColumns(),
        getActionColumn({ openDeleteModal})
    ])

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            disableSearch={true}
            columns={columns.current}
            dataPath = '/api/coreqs/approval'
            refresh={refresh}
        />
    ),[refresh]);

    return (
        <>
            <DeleteModal
                onDelete={()=>setRefresh(n=>n+1)}
                coreq={deleteCoreq}
            />
            <Box px={2}>
                <Wallet setWalletData={setWalletData}/>
            </Box>
            <Box sx={{p:2}}>
                <AddButton onAdd={()=>setRefresh(n=>n+1)} walletData={walletData}/>
            </Box>
            {mOrderTable}
        </>
    );
}

const AddButton = ({onAdd, walletData})=>{
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        value:''
    });

    return (
        <>
            <SubmitModal
                modalOpen={open}
                onCloseModal={()=>setOpen(false)}
                title={'Request Cashout'}
                submitPath={'/api/coreqs/approval'}
                submitMethod='post'
                submitLabel="Submit"
                succSubmitMessage="Successfully requested."
                onSubmit={onAdd}
                getSubmitBody={()=>{
                    return JSON.stringify(formData);
                }}
            >
                <TextField
                    variant='outlined' size="small" type='number'
                    label={"Amount"} required fullWidth
                    value={formData.value}
                    onWheel={(e) => {
                        e.target.blur(); 
                        setTimeout(() => {
                            e.target.focus()
                        }, 0);
                    }}
                    onChange={(e)=>{
                        setFormData(n=>{
                            return {
                                ...n,
                                value: e.target.value
                            }
                        })
                    }}
                    inputProps={{min:0.00, step:0.01, max:walletData.balance}}
                />
            </SubmitModal>
            <Button onClick={()=>setOpen(true)} variant='contained'>
                Request Cashout
            </Button>
        </>
    )
}

const DeleteModal = ({coreq, onDelete})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(coreq.id){
            setOpen(true);
        }
    },[coreq])
    
    return (
        <SubmitModal
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
            title={'Delete Request'}
            submitPath={'/api/coreqs/approval/'+coreq.id}
            submitMethod='delete'
            submitLabel="Delete"
            succSubmitMessage="Successfully deleted request."
            onSubmit={onDelete}
        >
            <Typography>Delete request for {coreq.value}?</Typography>
        </SubmitModal>
    )
}

export default CoreqsApprovalTable;