import { Alert, Box, Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as XLSX from 'xlsx/xlsx.mjs';
import React from "react";
import LoadingOverlay from "../LoadingOverlay";
import SubmitModal from "../SubmitModal";

const getRowValue = (row, property)=>{
    for(const rowProperty of Object.keys(row)){
        if(rowProperty.trim().toLowerCase() === property.toLowerCase())
            return row[rowProperty];
    }
    return null;
}

const defaultImportData = [];
const ImportItemsModal = ({open, onClose, onImport})=>{
    const [loading, setLoading] = React.useState(false);
    const [importData, setImportData] = React.useState(defaultImportData);
    const [error, setError] = React.useState('');

    React.useEffect(()=>{
        if(open){
            setError('');
            setImportData(defaultImportData);
        }
    },[open]);

    const parseWorkbook=async (workbook)=>{
        try{
            let newItems = [];
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
            console.log(jsonData);
            if(jsonData.length < 1) throw new Error('Empty file');

            for(let i = 0 ; i<jsonData.length; i++){
                let itemRow = jsonData[i];
                let newItem = {};
                newItem.name = getRowValue(itemRow, 'item name');
                newItem.variant = getRowValue(itemRow, 'variant');
                newItem.description = getRowValue(itemRow, 'description');
                newItem.quantity = getRowValue(itemRow, 'quantity');
                newItem.price = getRowValue(itemRow, 'price');

                if(newItem.name==='') throw new Error('Missing item name at row '+(i+2));
                if(!newItem.variant==='') throw new Error('Missing variant at row '+(i+2));
                if(!newItem.quantity==='') throw new Error('Missing quantity at row '+(i+2));
                if(!newItem.price==='') throw new Error('Missing price at row '+(i+2));

                if((newItem.variant.length+'').length > 3) throw new Error('Variant cannot exceed three characters at row '+(i+2));

                newItem.quantity = parseFloat(newItem.quantity);
                newItem.price = parseFloat(newItem.price);

                if(isNaN(newItem.quantity)) throw new Error('Quantity must be a number at row '+(i+2));
                if(isNaN(newItem.price)) throw new Error('Price must be a number at row '+(i+2));

                newItems.push(newItem);
            }

            setImportData(newItems)
        }catch(e){
            setError(e.message);
        }
    }

    const handleCapture = async (e)=>{
        if(!e.target.files[0]) return;
        let reader = new FileReader();
        reader.onload = async (e2)=>{
            setLoading(true);
            let data = e2.target.result;
            let workbook = XLSX.read(data);

            console.log(workbook);
            await parseWorkbook(workbook);
            setLoading(false);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
        e.target.value = '';
    }
    const handleSubmit = ()=>{
        setImportData(defaultImportData);
        onImport();
        onClose();
    }
    return (
        <SubmitModal
            title={'Import Items'}
            succSubmitMessage="Successfully imported items."
            submitPath={'/api/items/approval/batch'}
            submitMethod="post"
            submitLabel="Import"
            getSubmitBody={()=>{
                return JSON.stringify(importData);
            }}
            modalOpen={open}
            onCloseModal={onClose}
            onSubmit={handleSubmit}
            canSubmit={importData.length > 0 && !loading}
            maxWidth={'md'}
        >
            <Box sx={{position:'relative'}}>
                <LoadingOverlay loading={loading}/>
                <Typography gutterBottom>
                    File must have the following headers: <b>ITEM NAME, VARIANT, DESCRIPTION, QUANTITY, PRICE</b>
                </Typography>
                <Box>
                    <input
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden
                        id="icon-button-file"
                        onChange={handleCapture}
                        type="file"
                    />
                    <label htmlFor="icon-button-file">
                        <Button variant='contained' startIcon={<AttachFileIcon/>} component='span'>
                            Choose File
                        </Button>
                    </label>
                </Box>
                <Box>
                    {
                        error ? 
                            <Alert sx={{mt:2}} severity="error">{error}</Alert>
                        : ''
                    }
                </Box>
                {
                    importData.length > 0 ?
                    <Box sx={{mt:2}}>
                        <Box>
                            <Typography gutterBottom><b>New Items: </b></Typography>
                            <Divider/>
                            <TableContainer>
                                <Table size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Item Name
                                            </TableCell>
                                            <TableCell>
                                                Variant
                                            </TableCell>
                                            <TableCell>
                                                Description
                                            </TableCell>
                                            <TableCell>
                                                Quantity
                                            </TableCell>
                                            <TableCell>
                                                Price
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            importData.map((newItem, i)=>{
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell>
                                                            {newItem.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {newItem.variant}
                                                        </TableCell>
                                                        <TableCell>
                                                            {newItem.description}
                                                        </TableCell>
                                                        <TableCell>
                                                            {newItem.quantity}
                                                        </TableCell>
                                                        <TableCell>
                                                            {newItem.price}
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                    : null
                }
                <Box>

                </Box>
            </Box>
        </SubmitModal>
    )
}

export default ImportItemsModal;