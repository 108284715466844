import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Typography } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext"
import ItemSoldTable from '../../Components/VendorItems/ItemSoldTable';
import AdminItemSoldTable from '../../Components/VendorItems/AdminItemSoldTable';

const VendorItemsSold = function(){
    const {user} = React.useContext(UserContext);

    return user ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Vendor Items Sold
            </Typography>
            { (user.permissions.includes('admin') || user.permissions.includes('staff')) ? <AdminItemSoldTable/>:<ItemSoldTable/> }
        </Page>
        
    )
    : <Navigate to="/signin" />;
}

export default VendorItemsSold;
export const routeData = new RouteData({
    path:"/vendoritems/sold",
    element:<VendorItemsSold />,
},{
    name:"Sold Items",
    icon:<PlaylistAddCheckIcon />,
    isVisible:(user)=>user,
});