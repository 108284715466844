import React from "react";
import RouteData from "./RouteData";
import { Login } from "@mui/icons-material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";

import { UserContext } from "../Contexts/UserContext";
import { Navigate, useNavigate } from "react-router-dom";
import { FetchersContext } from "../Contexts/FetchersContext";

const Signin = () =>{
    const {user, setUser} = React.useContext(UserContext);
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const navigate = useNavigate();
    const [inputEnabled, setInputEnabled] = React.useState(true);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleChange = (setter)=>{
        return async (event)=>{
            setter(event.target.value);
            event.preventDefault();
        };
    }

    const handleSubmit = async (event)=>{
        event.preventDefault();
        setInputEnabled(false);
        fetchCSRF({
            succ:csrf=>{
                fetchAuthed(
                    {
                        path:'api/auth/signin',
                        method:'POST',
                        headers:{
                            'Content-Type':'application/JSON',
                            'X-CSRF-Token':csrf
                        },
                        body:JSON.stringify({
                            'username':username,
                            'password':password
                        }),
                    },{
                        succ:data=>{
                            setUser(data.result);
                            navigate('/',{replace:true});
                        }
                    }
                );
            },
            final:()=>setInputEnabled(true),
        });
    }

    return(
        user?
        <Navigate to='/' replace/>
        :
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{mt:5}}
            >
            <Card sx={{minWidth:"300px", maxWidth:'400px', flexGrow:'1'}}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" textAlign="center">
                        Sign In
                    </Typography>
                    <Box component="form" px="16px" onSubmit={handleSubmit} sx={{
                        display:"flex",
                        flexDirection:"column"
                    }}>
                        <TextField disabled={!inputEnabled} name="username" variant="outlined" value={username} onChange={handleChange(setUsername)} required label="Username" size="small" sx={{my:"8px"}}/>
                        <TextField disabled={!inputEnabled} name="password" variant="outlined" value={password} onChange={handleChange(setPassword)} required type="password" size="small" label="Password" sx={{my:"8px"}}/>
                        <Button disabled={!inputEnabled} type="submit" variant="contained" sx={{mt:"16px"}}>Sign in</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
    
};
export default Signin;
export const routeData = new RouteData({
    path:'/signin', 
    element:<Signin/>, 
 },{
    name:"Sign In",
    icon:<Login/>,
    isVisible:(user)=>!user,
 });