import React from 'react';

import { Typography, Box, Stack, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogActions, Divider} from '@mui/material';
import { FetchersContext } from '../../Contexts/FetchersContext';
import LoadingOverlay from '../LoadingOverlay';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

const numberFormat = new Intl.NumberFormat('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
const defaultSale = {
    saleItems: []
}
const ReceiptModal = function({sale=defaultSale}){
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [receiptMsgs, setReceiptMsgs] = React.useState({
        receiptMessage:'',
        receiptDetails:''
    })

    const toPrint = React.useRef();
    const handlePrint = useReactToPrint({
        content: ()=>toPrint.current
    })

    React.useEffect(()=>{
        setLoading(true);
        fetchAuthed({
            path:'/api/website'
        },{
            succ:(data)=>{
                setReceiptMsgs(data.result);
            },
            final:()=>{
                setLoading(false);
            }
        })
    },[fetchAuthed])

    React.useEffect(()=>{
        if(sale.id){
            setOpen(true);
        }
    },[sale])

    return (
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            fullWidth maxWidth={'sm'}
        >
            <DialogTitle >
                Receipt
            </DialogTitle>
            <DialogContent dividers sx={{position:'relative', justifyContent:'center', display:'flex'}}>
                <LoadingOverlay loading={loading}/>
                <Box width={400} ref={toPrint} sx={{py:2}}>
                    <Box textAlign={'center'} sx={{mb:2}}>
                        <Typography variant="body1" whiteSpace={'break-spaces'}>{receiptMsgs.receiptDetails}</Typography>
                        <Typography variant="caption">Date of Sale: {
                            sale.dateOfSale ? moment(sale.dateOfSale).format('MMMM DD YYYY, h:mm a') : ''
                        }</Typography>
                        <Typography variant="subtitle2">Receipt No. {sale.receiptNumber}</Typography>
                    </Box>
                    <Divider/>
                    <TableContainer>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={50}>Qty</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Subtotal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sale.saleItems?.map(saleItem=>{
                                        return (
                                            <TableRow key={saleItem.id}>
                                                <TableCell>{saleItem.quantity}</TableCell>
                                                <TableCell>{saleItem.sku+' - '+saleItem.name+'('+saleItem.variant+')'}</TableCell>
                                                <TableCell align="right">{saleItem.priceTotal}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack sx={{mt:2, px:2}} direction={'column'} spacing={"4px"}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant='body2'><b>Amount Payable:</b></Typography>
                            <Typography variant='body2'>{numberFormat.format(sale.priceTotal)}</Typography>
                        </Box>
                        <Typography variant="body2">Amount Received: </Typography>
                        <Box display={'flex'} justifyContent={'space-between'} pl={2}>
                            <Typography variant='body2'>Cash Amount Received:</Typography>
                            <Typography variant='body2'>{numberFormat.format(sale.cashPayment)}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} pl={2}>
                            <Typography variant='body2'>Electronic Amount Received:</Typography>
                            <Typography variant='body2'>{numberFormat.format(sale.electronicPayment)}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant='body2'><b>Total Amount Received:</b></Typography>
                            <Typography variant='body2'>{numberFormat.format(parseFloat(sale.cashPayment) + parseFloat(sale.electronicPayment))}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant='body2'><b>Change Given:</b></Typography>
                            <Typography variant='body2'>{numberFormat.format(sale.changeGiven)}</Typography>
                        </Box>
                    </Stack>
                    <Box textAlign={'center'} sx={{mt:2}}>
                        <Typography variant="body2">{receiptMsgs.receiptMessage}</Typography>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
                <Button onClick={()=>{
                    handlePrint(null, ()=>toPrint.current);
                }}>Print</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReceiptModal;