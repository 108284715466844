import React from "react"
import Page from "../../Components/Page";
import RouteData from "../RouteData";
import { Await, defer, useLoaderData} from "react-router-dom";
import AsyncErrorElement from "../../Components/AsyncErrorElement";
import AnnouncementInfo from "../../Components/Announcements/AnnouncementInfo";

const AnnouncementPage = ()=>{
    const {fetchedData} = useLoaderData();
    return(
        <Page>
            <React.Suspense fallback={(<div>loading...</div>)}>
                <Await
                    resolve={fetchedData}
                    errorElement={<AsyncErrorElement/>}
                >
                    {(data)=>{
                        return <AnnouncementInfo announcement={data.result}/>
                    }}
                    
                </Await>
            </React.Suspense>
        </Page>
    )
}

export default AnnouncementPage;
export const routeData = new RouteData({
    path:'/announcements/:annId', 
    element:<AnnouncementPage/>,
    loader:async({params})=>{
        let fetchPromise = fetch('/api/announcements/'+params.annId);
        let jsonPromise = fetchPromise.then(async (response)=>{
                if(!response.ok) throw new Response(response.statusText, {status:response.status});
                return response.json()
            })
        return defer({
            fetchedData: jsonPromise,
        });
    }
},{
    noTab:true
});