import React from "react";

import moment from "moment";
import FullTable from "../FullTable";
import { Alert, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import SubmitModal from "../SubmitModal";

const getColumns = ({openApproveModal, openDeleteModal})=>{
    const columns = [{
        id: "dateRequested",
        label: "Date Requested",
        minWidth:60,
        sortable:true,
        getValue:(coreq)=>{
            return moment(coreq.dateRequested).format('ll')
        }
    },{
        id: "vendorUsername",
        label: "Vendor",
        minWidth:30,
    },{
        id: "value",
        label: "Amount",
        minWidth:30,
    },{
        id:'actions',
        label: 'Actions',
        minWidth:'110',
        actions:[{
            title:'Approve',
            fn:(value)=>{
                openApproveModal(value);
            },
            icon:<CheckIcon fontSize="inherit"/>
        },{
            title:'Remove',
            fn:(value)=>{
                openDeleteModal(value);
            },
            icon:<DeleteIcon fontSize="inherit"/>
        }]
    }];
    return columns;
};
const AdminCoreqsApprovalTable = function(){
    const [approveCoreq, setApproveCoreq] = React.useState({});
    const [deleteCoreq, setDeleteCoreq] = React.useState({});
    const [refresh, setRefresh] = React.useState(0);

    const openApproveModal=(coreq)=>{
        setApproveCoreq({...coreq});
    }

    const openDeleteModal = (coreq)=>{
        setDeleteCoreq({...coreq});
    }

    const columns = React.useRef([
        ...getColumns({openApproveModal, openDeleteModal})
    ])

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            disableSearch={true}
            columns={columns.current}
            dataPath = '/api/coreqs/approval/admin'
            refresh={refresh}
        />
    ),[refresh]);

    return (
        <>
            <ApproveModal coreq={approveCoreq} onApprove={()=>setRefresh(n=>n+1)}/>
            <DeleteModal coreq={deleteCoreq} onDelete={()=>setRefresh(n=>n+1)}/>
            {mOrderTable}
        </>
    );
}

const ApproveModal = ({coreq, onApprove})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(coreq.id){
            setOpen(true);
        }
    }, [coreq]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully approved request."
            submitMethod="put"
            submitLabel="Approve"
            submitPath={"/api/coreqs/approval/approve/"+coreq.id}
            onSubmit={onApprove}
            title='Approve Request'
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
        >
            <Typography>Approve request of {coreq.value} by {coreq.vendorUsername}?</Typography>
        </SubmitModal>
    )
}

const DeleteModal = ({coreq, onDelete})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(coreq.id){
            setOpen(true);
        }
    }, [coreq]);

    return (
        <SubmitModal
            succSubmitMessage="Successfully deleted request."
            submitMethod="delete"
            submitLabel="Delete"
            submitPath={"/api/coreqs/approval/"+coreq.id}
            onSubmit={onDelete}
            title='Delete Request'
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
        >
            <Alert severity="warning">Delete request of {coreq.value} by {coreq.vendorUsername}?</Alert>
        </SubmitModal>
    )
}

export default AdminCoreqsApprovalTable;