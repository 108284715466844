import React from "react";
import RouteData from "../RouteData";
import { Await, defer, Navigate, useLoaderData } from "react-router-dom";
import Page from "../../Components/Page";
import { UserContext } from "../../Contexts/UserContext";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from '@mui/material/Box';
import AsyncErrorElement from "../../Components/AsyncErrorElement";
import Grid from '@mui/material/Unstable_Grid2';
import moment from "moment";
import ProfileDropTable from "../../Components/Users/ProfileDropTable";

const UserProfile = ()=>{
    const {user} = React.useContext(UserContext);
    const {fetchedData} = useLoaderData();

    return (
        <Page>
            {
                user && user.permissions.includes('admin') ?
                <Box>
                    <Typography sx={{p:2}} variant='h6'>User Profile</Typography>
                    <Divider/>
                    <React.Suspense fallback={(<div>loading...</div>)}>
                        <Await
                            resolve={fetchedData}
                            errorElement={<AsyncErrorElement/>}
                        >
                            {(data)=>{
                                console.log(data);
                                return (<Profile user={data.result}/>)
                            }}
                            
                        </Await>
                    </React.Suspense>
                </Box>
                :
                user ? 
                <Typography>Restricted Access</Typography>
                :
                <Navigate to='/signin'/>
            }
        </Page>
    );
}

const Profile = ({user})=>{

    const mOrderTable = React.useMemo(()=>(
        <ProfileDropTable
            dataPath={'/api/users/drops/'+user.id}
        />
    ),[user]);

    return (
        <Box>
            <Box sx={{px:2,my:2}}>
                <Grid container>
                    <Grid xs={12} sm={6}>
                        <Typography><b>Username:</b> {user.username}</Typography>
                        <Typography><b>Name:</b> {user.name}</Typography>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Typography><b>Account Created On:</b> {moment(user.createdAt).format('LL')}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Typography variant='h6' align="center">Dropped Items</Typography>
                {mOrderTable}
            </Box>
        </Box>
    );
}

export const routeData = new RouteData({
    path:'/users/:userId',
    element: <UserProfile/>,
    loader:async({params})=>{
        let fetchPromise = fetch('/api/users/'+params.userId);
        let jsonPromise = fetchPromise.then(async (response)=>{
                if(!response.ok) throw new Response(response.statusText, {status:response.status});
                return response.json()
            })
        return defer({
            fetchedData: jsonPromise,
        });
    }
},{noTab:true})