import React from 'react';

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import RouteData from '../RouteData';
import { Navigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext";

import UserTable from '../../Components/Users/UserTable';
import UserTabs from '../../Components/Users/UserTabs';
import ReferralLinkModal from '../../Components/Users/ReferralLinkModal';
import RefIDTable from '../../Components/Users/RefIDTable';
import Page from '../../Components/Page';

const Users = function(){
    const {user} = React.useContext(UserContext);
    const [tabInfo, setTabInfo] = React.useState({
        tab:'users',
        subTab:'',
    });
    const [refLinkModalOpen, setRefLinkModalOpen] = React.useState(false);
    const [refresh, setRefresh] = React.useState(0);
    
    const handleTabChange = (tabInfo)=>{
        setTabInfo(tabInfo);
    }

    return (
        <Page>
            {user ? "" : <Navigate to="/signin" />}
            {user && user.permissions.includes('admin') ? (<>
                <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                    Users
                </Typography>
                <Box sx={{px:2}}>
                    <Button 
                        color="success" 
                        startIcon={<AddCircleIcon />}
                        onClick={()=>setRefLinkModalOpen(true)}
                    >
                        Generate Referral Link
                    </Button>
                </Box>
                <ReferralLinkModal modalOpen={refLinkModalOpen} onGenerate={()=>setRefresh(n=>n+1)} handleCloseModal={()=>setRefLinkModalOpen(false)}/>
                <UserTabs onTabChange={handleTabChange} />
                
                <UserTable display={tabInfo.tab === 'users'}/>
                <RefIDTable refresh={refresh} display={tabInfo.tab === 'refIDs'}/>
                </>):
            (<>
               <Typography>
                    Access restricted.
                </Typography> 
            </>)}
        </Page>
    );
}

export default Users;
export const routeData = new RouteData({
    path:'/users',
    element:<Users/>,
},{
    name:'Users',
    icon:<PeopleAltIcon/>,
    isVisible: (user)=> user && user.permissions.includes('admin'),
    inPath:(path)=>path.match(/^\/users\/\w+$/gi),
});
