import RouteData from './RouteData';
import {Home as HomeIcon} from '@mui/icons-material';

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Page from '../Components/Page';
import { Divider} from '@mui/material';
import AnnouncementCardList from '../Components/Home/AnnouncementCardList';
import Footer from '../Components/Home/Footer';

function Home(){
    return (
        <Page >
            <Box display='flex' sx={{p:2, justifyContent:'center'}}
            >
                <Typography variant='h6' align="center">Announcements</Typography>
            </Box>
            <AnnouncementCardList/>
            <Divider sx={{my:2}}/>
            <Box sx={{mt:2,px:2,pb:2}}>
                <Footer/>
            </Box>
        </Page>
    );
}

export default Home;
export const routeData = new RouteData({
    path:'/', 
    element:<Home/>
},{
    name:'Home',
    icon: <HomeIcon/>,
    inPath:(path)=>path.match(/^\/announcements\/\w+$/gi),
});