import React from "react";

import RouteData from "../RouteData";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Navigate } from "react-router-dom";

const Sales = ()=>{
    return <Navigate to="/sales/receipts" replace={true}/>;
}

export const routeData = new RouteData({
    path:'/sales',
    element: <Sales/>
},{
    name: 'Sales',
    icon: <AttachMoneyIcon/>,
    isVisible: (user)=>{
        return user && (user.permissions.includes('admin') || user.permissions.includes('staff'));
    }
})