import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import {Checkbox} from '@mui/material';
import FullTable from '../../Components/FullTable';
import ItemDetailsModal from '../../Components/VendorItems/ItemDetailsModal';

const getColumns = (data)=>{
    return [{
        id:'check',
        label:'',
        minWidth:10,
        maxWidth:10,
        getValue:(v)=>{
            return <Checkbox
                checked={Boolean(data.selectedItems.find(i=>i.id === v.id))}
                onChange={(e)=>{
                    data.setItemSelected(v,e.target.checked);
                }}
                sx={{p:0}}
            />;
        }
    },{
        id:'sku',
        label:'SKU',
        minWidth:30,
    },{
        id:'vendorUsername',
        label:'Vendor',
        minWidth:30,
        getValue:(v)=>{
            return v.vendor?.username
        }
    },{
        id: "name",
        label: "Item Name",
        minWidth:60,
    },{
        id: "variant",
        label: "Variant",
        minWidth:60,
    },{
        id: "description",
        label: "Description",
        minWidth:60,
    },{
        id: "stock",
        label: "Stock",
        minWidth:20,
        getValue:(v)=>{
            return v.itemStock.stock
        }
    },{
        id: "price",
        label: "Price",
        minWidth:30,
    },{
        id:'actions',
        label: "Actions",
        minWidth:60,
        actions:[{
            title:'Details',
            fn:(value)=>{
                data.openDetailsItemModal(value);
            },
            icon:<MoreHorizIcon fontSize="inherit"/>
        }]
    }]
}

const ItemSelectTable = function({filters, selectedItems, setSelectedItems, refresh}){
    const [detailsItem, setDetailsItem] = React.useState({});

    const openDetailsItemModal = (item)=>{
        setDetailsItem({...item});
    }

    const setItemSelected = React.useCallback((item, selected)=>{
        setSelectedItems(items=>{
            items = [...items];
            let index = items.findIndex((i)=>i.id === item.id);
            if(selected){
                if(index<0) items.push(item);
            }else{
                if(index>-1) items.splice(index,1);
            }
            return items;
        })
    },[setSelectedItems]);

    const mTable = React.useMemo(()=>{
        return (
            <FullTable
                columns={getColumns({openDetailsItemModal, selectedItems, setItemSelected, tf:'asd'})}
                disableSearch={true}
                dataPath='/api/items/inventory/checkout'
                urlSearchParams={filters}
                refresh={refresh}
            />
        );
    },[filters, selectedItems, setItemSelected, refresh])

    return (
        <>
            {mTable}
            <ItemDetailsModal item={detailsItem}/>
        </>
    );
}

export default ItemSelectTable;