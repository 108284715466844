import React from 'react';
import RouteData from '../RouteData';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Navigate } from 'react-router-dom';

const VendorItems = function(){
    return <Navigate to="/vendoritems/approval" replace={true}/>;
}

export default VendorItems;
export const routeData = new RouteData({
    path:"/vendoritems",
    element:<VendorItems />,
},{
    name:"Vendor Items",
    icon:<InventoryIcon />,
    isVisible:(user)=>user,
});