import { Alert } from "@mui/material";
import React from "react";
import SubmitModal from "../SubmitModal";

const DeleteAnnouncementModal = ({announcement, onRemove})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(announcement.id) setOpen(true);
    },[announcement]);

    return(
        <SubmitModal
            modalOpen={open}
            onCloseModal={()=>setOpen(false)}
            submitMethod='delete'
            submitContentType=""
            title='Delete Announcement'
            submitPath={'/api/announcements/'+announcement.id}
            submitLabel='Delete'
            succSubmitMessage="Successfully deleted announcement"
            onSubmit={onRemove}
            maxWidth='xs'
        >
            <Alert severity='warning'>Are you sure you want to delete announcement: '{announcement.title}'</Alert>
        </SubmitModal>
    )
}

export default DeleteAnnouncementModal;