import React from 'react';
import RouteData from '../RouteData';

import Page from '../../Components/Page';
import { Navigate } from 'react-router-dom';
import { UserContext } from "../../Contexts/UserContext";
import { Typography } from "@mui/material";
import FullTable from '../../Components/FullTable';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const getColumns = ()=>{
    const columns = [{
        id: "vendorUsername",
        label: "Vendor",
        minWidth:30,
    },{
        id: "vendorCode",
        label: "Code",
        minWidth:30,
    },{
        id: "soldTotal",
        label: "Sold Total",
        minWidth:30,
    },{
        id: "taxTotal",
        label: "Tax Total",
        minWidth:30,
    },{
        id: "taxedTotal",
        label: "Encashment Total",
        minWidth:30,
        getValue:(v)=>{
            return (parseFloat(v.soldTotal) - parseFloat(v.taxTotal)).toFixed(2);
        }
    },{
        id: "encashedTotal",
        label: "Encashed Total",
        minWidth:30,
    },{
        id:'balance',
        label:'Balance',
        minWidth:30
    }];
    return columns;
};

const Wallets = function(){
    const {user} = React.useContext(UserContext);
    const columns = React.useRef([
        ...getColumns()
    ])

    const mTable = React.useMemo(()=>{
        return (
            <FullTable
                columns={columns.current}
                dataPath = '/api/coreqs/wallets'
                getRowId={(row)=>row.vendorId}
                disableSearch={true}
            />
        )
    },[])

    return user && user.permissions.includes('admin') ? (
        <Page sx={{position:'relative'}}>
            <Typography variant="h6" sx={{p:2,pb:0}} color="primary">
                Wallets
            </Typography>
            {mTable}
        </Page>
        
    ): user ? <Navigate to="/" replace={true}/>
    : <Navigate to="/signin" />;
}

export default Wallets;
export const routeData = new RouteData({
    path:"/coreqs/wallets",
    element:<Wallets />,
},{
    name:"Wallets",
    icon:<AccountBalanceWalletIcon />,
    isVisible:(user)=>Boolean(user) && user.permissions.includes('admin'),
});