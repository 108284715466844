import React from 'react';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import {Card, CardMedia, CardContent, CardActionArea, Box} from '@mui/material';
import { Link } from 'react-router-dom';

const emptyJSON = {};
const AnnouncementCard = ({ announcementData=emptyJSON})=>{
    return(
        <Card sx={{height:'100%'}} >
            <CardActionArea sx={{height:'100%'}} component={Link} to={"/announcements/"+announcementData.id}>
                {announcementData.imgName ? 
                    <CardMedia
                        sx={{ 
                            height: {
                                xs:100,
                                sm:125,
                                md:150,
                            }
                        }}
                        image={'/api/announcements/image/'+announcementData.imgName}
                        title={announcementData.title}
                    />
                : null}
                <CardContent 
                    sx={{
                        height: announcementData.imgName ? {
                            xs:'calc(100% - 100px)',
                            sm:'calc(100% - 125px)',
                            md:'calc(100% - 150px)',
                        } : '100%',
                        display:'flex',
                        flexDirection:'column'
                    }}
                >
                    <Typography gutterBottom variant="h6" sx={{maxHeight:'2lh', overflow:'hidden', textOverflow:'ellipsis'}}>
                        {announcementData.title}
                    </Typography>
                    <Typography gutterBottom variant="body2" whiteSpace='pre-wrap' color="text.secondary" 
                        sx={{
                            height:'4lh',
                            maxHeight: announcementData.imgName ? '4lh' : undefined,
                            flexGrow: announcementData.imgName ? undefined : 1,
                            overflow:'hidden',
                            textOverflow:'ellipsis'
                        }}
                    >
                        {announcementData.text}
                    </Typography>
                    <Box sx={{flexGrow:announcementData.imgName ? 1: undefined}}></Box>
                    <Box>
                        <Typography variant='body2' align="right">
                            {moment(announcementData.dateCreated).format('ll')}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default AnnouncementCard;