import React from "react";

import moment from "moment";
import FullTable from "../FullTable";
import { Box, Button } from "@mui/material";
import Wallet from "./Wallet";
import ExportModal from "../ExportModal";

const getColumns = ()=>{
    const columns = [{
        id: "dateRequested",
        label: "Date Requested",
        minWidth:60,
        sortable:true,
        getValue:(coreq)=>{
            return moment(coreq.dateRequested).format('ll')
        }
    },{
        id: "dateApproved",
        label: "Date Approved",
        minWidth:60,
        sortable:true,
        getValue:(coreq)=>{
            return moment(coreq.cashOutApproval?.dateApproved).format('ll')
        }
    },{
        id: "approver",
        label: "Approved By",
        minWidth:60,
        getValue:(coreq)=>{
            return coreq.cashOutApproval?.approverUsername
        }
    },{
        id: "amount",
        label: "Amount",
        minWidth:30,
        getValue:(coreq)=>{
            return coreq.value;
        }
    }];
    return columns;
};
const CoreqsApprovedTable = function(){
    const columns = React.useRef([
        ...getColumns()
    ])

    const mOrderTable = React.useMemo(()=>(
        <FullTable
            disableSearch={true}
            columns={columns.current}
            dataPath = '/api/coreqs/approved'
        />
    ),[]);

    return (
        <>
            <Box px={2}>
                <Wallet/>
            </Box>
            {mOrderTable}
            
            <Box display={'flex'} flexDirection={'row'} justifyContent={'end'} p={2}>
                <ExportButton/>
            </Box>
        </>
    );
}

const ExportButton = ()=>{
    const [modalOpen, setModalOpen] = React.useState(false);

    const getCsvData = (data)=>{
        let csvData = [];
        csvData.push([
            'Date Requested', 'Vendor', 'Amount',
            'Date Approved', 'Approver'
        ]);

        data.forEach(coreq=>{
            csvData.push([
                moment(coreq.dateRequested).format('LL'), coreq.vendorUsername, coreq.value,
                moment(coreq.cashOutApproval?.dateApproved), coreq.cashOutApproval?.approverUsername
            ])
        })
        return csvData;
    }

    return (
        <>
            <Button variant='contained' onClick={()=>setModalOpen(true)}>Export</Button>
            <ExportModal
                open={modalOpen}
                onClose={()=>setModalOpen(false)}
                title={"Export CashOut Requests"}
                path={'/api/coreqs/approved'}
                fileName={'coreqs'}
                getCsvData={getCsvData}
            />
        </>
    )
}

export default CoreqsApprovedTable;