import React from "react";
import { FetchersContext } from "../../Contexts/FetchersContext";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LoadingOverlay from "../LoadingOverlay";

const defaultSetWalletData = (data)=>{}
const Wallet = ({setWalletData = defaultSetWalletData})=>{
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({});

    React.useEffect(()=>{
        setLoading(true);
        fetchAuthed({
            path:'/api/coreqs/wallet'
        },{
            succ:(data)=>{
                setData(data.result);
                setWalletData(data.result);
            },
            final:()=>{
                setLoading(false);
            }
        })
    },[fetchAuthed, setWalletData])

    return (
        <Box position={'relative'}>
            <LoadingOverlay loading={loading}/>
            <Grid container>
                <Grid xs={12} sm={6}>
                    <Detail label='Vendor Code' value={data.vendorCode}/>
                    <Detail label='Sold Total' value={data.soldTotal}/>
                    <Detail label='Tax Total' value={data.taxTotal}/>
                </Grid>
                <Grid xs={12} sm={6}>
                    <Detail label='Encashed Total' value={data.encashedTotal}/>
                    <Detail label='Balance' value={data.balance}/>
                </Grid>
            </Grid> 
        </Box>
    );
}

const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}

export default Wallet;