import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Box from '@mui/material/Box';
import moment from "moment";
import { UserContext } from "../../Contexts/UserContext";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

const defaultOrder = {
    id:'',
    createdAt:'',
    updatedAt:'',
};
const OrderDetailsModal =({children,order=defaultOrder})=>{
    const [open, setOpen] = React.useState(false);
    const {user} = React.useContext(UserContext);

    React.useEffect(()=>{
        if(order.id)
            setOpen(true);
    },[order]);

    return (
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            fullWidth maxWidth={'sm'}
        >
            <DialogTitle >
                Item Order Details
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid xs={12} sm={6}>
                        {user && user.permissions.includes('admin') ? <Detail label='Order ID' value={order.id}/> : null }
                        <Detail label='Sale Id' value={order.saleId}/>
                        <Detail label='Items' value={order.items ? order.items.length : 0}/>
                        <Detail label='Customer' value={order.customer}/>
                        <Detail label='Total Price' value={order.totalPrice}/>
                        <Detail label='Note' value={order.note}/>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Detail label='Tracker Id' value={order.trackerId}></Detail>
                        <Detail label='Sold On' value={moment(order.saleDate).format('LL')}></Detail>
                        <Detail label='Created On' value={moment(order.createdAt).format('LL')}></Detail>
                        <Detail label='Updated On' value={moment(order.updatedAt).format('LL')}></Detail>
                        <Detail label='Creator' value={order.creator?.username}/>
                        {order.claimDate ? <Detail label='Claim Date' value={moment(order.claimDate).format('LL')}></Detail> : null}
                    </Grid>
                </Grid>
                {order.orderDrop ? 
                    <Box sx={{mt:2}}>
                        <Typography align='center' gutterBottom><b>Drop Details</b></Typography>
                        <Grid container>
                            <Grid xs={12} sm={6}>
                                <Detail label='Drop Location' value={order.orderDrop.dropLocation}/>
                                <Detail label='Drop Date' value={moment(order.orderDrop.dropDate).format('LL')}/>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Detail label='Handling Fee' value={order.orderDrop.handlingFee}/>
                                <Detail label='Dropper' value={order.orderDrop.dropper?.username}/>
                            </Grid>
                        </Grid> 
                    </Box>
                : null}
                {order.orderApproval ? 
                    <Box sx={{mt:2}}>
                        <Typography align='center' gutterBottom><b>Approval Details</b></Typography>
                        <Grid container>
                            <Grid xs={12} sm={6}>
                                <Detail label='Approval Date' value={moment(order.orderApproval.approvalDate).format('LL')}/>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Detail label='Approver' value={order.orderApproval.approver?.username}/>
                            </Grid>
                        </Grid> 
                    </Box>
                : null}
                {order.orderShipment ? 
                    <Box sx={{mt:2}}>
                        <Typography align='center' gutterBottom><b>Shipment Details</b></Typography>
                        <Grid container>
                            <Grid xs={12} sm={6}>
                                <Detail label='Ship Date' value={moment(order.orderShipment.shipDate).format('LL')}/>
                                <Detail label='Ship Mode' value={order.orderShipment.mode}/>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Detail label='Ship Status' value={order.orderShipment.status}/>
                                <Detail label='Shipper' value={order.orderShipment.shipper?.username}/>
                            </Grid>
                        </Grid> 
                    </Box>
                : null}
                {order.orderWalkIn ? 
                    <Box sx={{mt:2}}>
                        <Typography align='center' gutterBottom><b>Walk-In Details</b></Typography>
                        <Grid container>
                            <Grid xs={12} sm={6}>
                                <Detail label='Walk-In Date' value={moment(order.orderWalkIn.buyDate).format('LL')}/>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Detail label='Handler' value={order.orderWalkIn.handler?.username}/>
                            </Grid>
                        </Grid> 
                    </Box>
                : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
                {user && user.permissions.includes('admin') ? 
                    <Button component={Link} to={'/orders/edit/'+order.id}>
                        Edit
                    </Button>
                : null }
            </DialogActions>
        </Dialog>
    );
}

const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}

export default OrderDetailsModal;