import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import moment from "moment";

const defaultItem = {};
const ItemDetailsModal =({item=defaultItem})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(item !== defaultItem && item?.id){
            setOpen(true);
        }
    },[item]);

    return (
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            fullWidth maxWidth={'sm'}
        >
            <DialogTitle >
                Item Details
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid xs={12} sm={6}>
                        <Detail label='SKU' value={item?.sku}/>
                        <Detail label='Item Name' value={item?.name}/>
                        <Detail label='Variant' value={item?.variant}/>
                        <Detail label='Description' value={item?.description}/>
                        <Detail label='Vendor' value={item?.vendor?.username}/>
                        <Detail label='Vendor Code' value={item?.vendor?.vendorCode}/>
                        <Detail label='Price' value={item?.price}/>
                        <Detail label='Quantity' value={item?.quantity}/>
                        <Detail label='Notes' value={item?.notes}/>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Detail label='Date Added' value={item?.dateCreated ? moment(item.dateCreated).format('ll'): ''}/>
                        <Detail label='Approval Date' value={item?.itemApproval?.dateApproved ? 
                            moment(item.itemApproval.dateApproved).format('ll'): ''}/>
                        <Detail label='Approved By' value={item?.itemApproval?.approverUsername}/>
                        <Detail label='Location' value={item?.itemApproval?.location}/>
                        <Detail label='Tax' value={item?.itemApproval?.tax}/>
                    </Grid>
                </Grid> 
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

const Detail = ({label, value})=>{
    return(
        <Typography><b>{label}:</b> {value}</Typography>
    )
}

export default ItemDetailsModal;