import './App.css';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Root from './Routes/Root';
import {routeData as homeRD} from './Routes/Home';
//import {routeData as ordersRD} from './Routes/Orders';
import {routeData as signinRD} from './Routes/Signin';
import {routeData as signoutRD} from './Routes/Signout';
import {routeData as signupRD} from './Routes/Signup';
/*import {routeData as orderAddRD} from './Routes/Orders/Add';
import {routeData as orderEditRD} from './Routes/Orders/Edit';
import {routeData as orderEditTargetRD} from './Routes/Orders/EditTarget';
import {routeData as orderSalesRD} from './Routes/Orders/Sales';
import {routeData as dropsRD} from './Routes/Drop Items';*/
import {routeData as usersRD} from './Routes/Users';
import {routeData as usersProfileRD} from './Routes/Users/Target';
/*import {routeData as trackersRD} from './Routes/Trackers';
import {routeData as trackersTargetRD} from './Routes/Trackers/Target';
import {routeData as trackerAddRD} from './Routes/Trackers/Add';*/
import {routeData as profileRD} from './Routes/Profile';
/*import {routeData as itemsRD} from './Routes/Items';
import {routeData as itemsTypesRD} from './Routes/Items/Types';
import {routeData as itemsTypesTargetRD} from './Routes/Items/TargetType';
import {routeData as itemsTargetRD} from './Routes/Items/TargetItem';
import {routeData as itemsLotsRD } from './Routes/Items/Lots';
import {routeData as itemsLotsTargetRD } from './Routes/Items/TargetLot';*/
import {routeData as announcementsRD} from './Routes/Announcements';
//import {routeData as orderTrackerRD} from './Routes/OrderTracker';
import {routeData as announcementTargetRD} from './Routes/Announcements/Target';
import {routeData as coreqsRD} from './Routes/Cashout Requests';
import {routeData as coreqsApprovalRD} from './Routes/Cashout Requests/forApproval';
import {routeData as coreqsApprovedRD} from './Routes/Cashout Requests/approved';
import {routeData as coreqsWalletsRD} from './Routes/Cashout Requests/wallets';
import {routeData as vendorItemsRD} from './Routes/Vendor Items';
import {routeData as vendorItemsApprovalRD} from './Routes/Vendor Items/forApproval';
import {routeData as VendorItemsInventoryRD} from './Routes/Vendor Items/inventory';
import {routeData as vendorItemsSoldRD} from './Routes/Vendor Items/soldItems';
import {routeData as checkOutRD} from './Routes/Check Out';
import {routeData as salesRD} from './Routes/Sales';
import {routeData as salesReceiptsRD} from './Routes/Sales/Receipts';
import {routeData as salesReportRD} from './Routes/Sales/Report';
import ErrorElement from './Components/ErrorElement';

//const pathGroups = [[homeRD],[itemsRD, itemsAddRD],[ordersRD, orderAddRD, orderEditRD, orderEditTargetRD],[trackersRD, trackersTargetRD, trackerAddRD],[usersRD, usersProfileRD, profileRD,signoutRD,signinRD,signupRD]];
const pathGroups = [homeRD,/*orderTrackerRD,*/ announcementsRD, announcementTargetRD, 
  [vendorItemsRD, vendorItemsApprovalRD, VendorItemsInventoryRD, vendorItemsSoldRD], 
  checkOutRD,
  [salesRD, salesReceiptsRD, salesReportRD],
  //[itemsRD, itemsTargetRD, itemsTypesRD, itemsTypesTargetRD, itemsLotsRD, itemsLotsTargetRD],
  //[ordersRD, orderAddRD, orderEditRD, orderEditTargetRD, orderSalesRD],
  //dropsRD,
  [coreqsRD,coreqsApprovalRD, coreqsApprovedRD, coreqsWalletsRD],
  //[trackersRD, trackersTargetRD, trackerAddRD],
  usersRD, usersProfileRD, profileRD,signoutRD,signinRD,signupRD];
const router = createBrowserRouter([
  {
    path:"/",
    element:<Root pathGroups={pathGroups}/>,
    loader:async ()=>{
      let user = JSON.parse(sessionStorage.getItem('user'));
      if(user===null){
          let response = await (await fetch('/api/auth')).json();
          if(response.authenticated){
              user = response.result;
              console.log('setting session storage'+user);
              sessionStorage.setItem('user', JSON.stringify(user));
          }
      }
      return user;
    },
    children:pathGroups.flat(),
    errorElement:<ErrorElement/>
  },
]);

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <RouterProvider router = {router} />
    </LocalizationProvider>
  );
}

export default App;
