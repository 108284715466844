import React from 'react';
import Stack from '@mui/material/Stack';
import { Box, Button, Pagination } from '@mui/material';
import Announcement from './Announcement';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import AddAnnouncementModal from './AddAnnouncementModal';
import DeleteAnnouncementModal from './DeleteAnnouncementModal';
import { FetchersContext } from '../../Contexts/FetchersContext';
import EditAnnouncementModal from './EditAnnouncementModal';

const emptyJSON = {};
const AnnouncementList = ({ dataPath, searchQuery=emptyJSON, refresh:refreshProp})=>{
    const [pagination, setPagination] = React.useState({
        page:0,
        rowsPerPage:5,
    });
    const {fetchAuthed} = React.useContext(FetchersContext);
    const [refresh, setRefresh] = React.useState(0);
    const [addModalOpen, setAddModalOpen] = React.useState(false);

    const [deleteAnnouncement, setDeleteAnnouncement] = React.useState({
        id:'',title:'', text:'', imgName:''
    });
    const [editAnnouncement, setEditAnnouncement] = React.useState({
        id:'', title:'', text:"", imgName:''
    });

    const [listData, setListData] = React.useState({
        data:[],
        count:0
    });

    React.useEffect(()=>{
        let params = {
            limit:pagination.rowsPerPage,
            offset:pagination.rowsPerPage*pagination.page,
        };
        fetchAuthed({
            path:'/api/announcements?'+new URLSearchParams(params),
        },{
            succ:(data)=>{
                console.log(data);
                setListData({
                    data:data.result,
                    count:data.count
                });
            }
        });
    },[fetchAuthed, refresh, pagination]);

    const mList = React.useMemo(()=>{
        return(
            <Stack spacing={2}>
                {listData.data.map(announcement=>(
                    <Announcement
                        key={announcement.id}
                        announcementData = {announcement}
                        onRemove={()=>setDeleteAnnouncement({...announcement})}
                        onEdit={()=>setEditAnnouncement({...announcement})}
                    />            
                ))}
            </Stack>
        );
    },[listData]);

    return(
        <Box sx={{px:2,pb:2}}>
            <Grid container direction='row' sx={{mb:2}} spacing={2}>
                <Grid xs={12} sm={5}>
                    <Button variant='contained' onClick={()=>setAddModalOpen(true)}>Add Announcement</Button>
                </Grid>
                <Grid xs={12} sm={7}>
                    <Box sx={{display:'flex', justifyContent:'end'}}>
                        <Pagination 
                            count={Math.ceil(listData.count / pagination.rowsPerPage)} 
                            page={pagination.page+1} 
                            onChange={(e,v)=>setPagination(n=>({...n, page:v-1}))}
                        />
                    </Box>
                </Grid>
            </Grid>
            <AddAnnouncementModal 
                modalOpen={addModalOpen}
                onCloseModal={()=>setAddModalOpen(false)}
                onAdd = {()=>setRefresh(n=>n+1)}
            />
            <EditAnnouncementModal
                announcement={editAnnouncement}
                onEdit={()=>setRefresh(n=>n+1)}
            />
            <DeleteAnnouncementModal
                announcement={deleteAnnouncement}
                onRemove={()=>setRefresh(n=>n+1)}
            />
            {mList}
        </Box>
    );
}

export default AnnouncementList;