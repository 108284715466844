import React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const UserTabs = ({onTabChange})=>{
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event, nextValue, tabName, subTab='')=>{
        setTabValue(nextValue);
        onTabChange({
            tab:tabName,
            subTab:subTab,
        });
    };

    return(
        <Box sx={{ width:'100%',borderBottom: 1, borderColor: 'divider'}} >
            <Tabs 
                value={tabValue} 
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
            >
                <Tab label="Users" onClick={(event)=>{
                    handleTabChange(event,0, 'users');
                }}/>
                <Tab label="Referral IDs" onClick={(event)=>{
                    handleTabChange(event,1,'refIDs');
                }}/>
            </Tabs>
        </Box>
    );
}

export default UserTabs;