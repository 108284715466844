import React from "react";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import { Navigate, useLocation } from "react-router-dom";

import { UserContext } from "../../Contexts/UserContext";
import { FetchersContext } from "../../Contexts/FetchersContext";
import RouteData from "../RouteData";
import LoadingOverlay from "../../Components/LoadingOverlay";

const Signup = ()=>{
    const params = new URLSearchParams(useLocation().search);
    const {user, setUser} = React.useContext(UserContext);
    const {fetchCSRF, fetchAuthed} = React.useContext(FetchersContext);
    const [formData, setFormData] = React.useState({
        refID: params.get('refID') ? params.get('refID') : '',
        username:'',
        password:'',
        password2:'',
    });
    const [inputEnabled, setInputEnabled] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event)=>{
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    }

    const handleSubmit=(event)=>{
        event.preventDefault();
        setLoading(true);
        setInputEnabled(false);
        fetchCSRF({
            succ:(csrf)=>{
                fetchAuthed({
                    path:'/api/auth/signup',
                    method:'post',
                    headers:{
                        'content-type':'application/json',
                        'x-csrf-token':csrf,
                    },
                    body:JSON.stringify(formData),
                },{
                    succ:(data)=>{
                        setUser(data.result);
                    },
                    final:()=>{
                        setInputEnabled(true);
                        setLoading(false);
                    },
                    succMessage:'Sign up successful!'
                });
            }
        });
    }

    return (
        user?
        <Navigate to='/' replace/>
        :
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{mt:5}}
            >
            <Card sx={{minWidth:"300px", maxWidth:'400px', flexGrow:'1', position:'relative'}}>
                <LoadingOverlay loading={loading}/>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div" textAlign="center">
                        Sign Up
                    </Typography>
                    <Box component="form" px="16px" onSubmit={handleSubmit} sx={{
                        display:"flex",
                        flexDirection:"column"
                    }}>
                        <TextField disabled={!inputEnabled} name="refID" variant="outlined" value={formData.refID} onChange={handleChange} required size="small" label="Referral ID" sx={{my:"8px"}}/>
                        <TextField disabled={!inputEnabled} name="username" variant="outlined" value={formData.username} onChange={handleChange} required label="Username" size="small" sx={{my:"8px"}}/>
                        <TextField disabled={!inputEnabled} name="password" variant="outlined" value={formData.password} onChange={handleChange} required type="password" size="small" label="Password" sx={{my:"8px"}}/>
                        <TextField disabled={!inputEnabled} 
                            error={formData.password!==formData.password2} 
                            helperText={formData.password === formData.password2 ? '' : 'Passwords do not match'}
                            name="password2" variant="outlined" value={formData.password2} onChange={handleChange} required type="password" size="small" label="Re-enter Password" sx={{my:"8px"}}/>
                        
                        <Button disabled={
                            !inputEnabled || !formData.password || formData.password !== formData.password2
                        } type="submit" variant="contained" sx={{mt:"16px"}}>Sign Up</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Signup;
export const routeData = new RouteData({
    path:'/signup',
    element: <Signup/>,
},{
    name: 'Sign Up',
    icon: <AppRegistrationIcon/>,
    isVisible: (user)=>!user
})