import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const VendorSalesTable = ({vendorSales=[]})=>{
    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Vendor
                        </TableCell>
                        <TableCell>
                            Code
                        </TableCell>
                        <TableCell>
                            Total Sold
                        </TableCell>
                        <TableCell>
                            Total Tax
                        </TableCell>
                        <TableCell>
                            Encashment
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        vendorSales.map((vendorSaleReport)=>{
                            return (
                                <TableRow key={vendorSaleReport.vendorUsername}>
                                    <TableCell>
                                        {vendorSaleReport.vendorUsername}
                                    </TableCell>
                                    <TableCell>
                                        {vendorSaleReport.vendorCode}
                                    </TableCell>
                                    <TableCell>
                                        {vendorSaleReport.soldTotal}
                                    </TableCell>
                                    <TableCell>
                                        {vendorSaleReport.taxTotal}
                                    </TableCell>
                                    <TableCell>
                                        {vendorSaleReport.taxedTotal}
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default VendorSalesTable;