import React from 'react';
import {Close, Edit} from '@mui/icons-material';
import { Button, IconButton, Paper, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ReactLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { UserContext } from '../../Contexts/UserContext';
import SubmitModal from '../SubmitModal';

const Footer = ()=>{
    const {user} = React.useContext(UserContext);
    const [openLinksModal, setOpenLinksModal] = React.useState(false);
    const [openAboutModal, setOpenAboutModal] = React.useState(false);

    const [wsInfo, setWSInfo] = React.useState({
        links:[],
        about:''
    });
    const [refresh, setRefresh] = React.useState(0);

    React.useEffect(()=>{
        fetch('/api/website')
        .then(async(response)=>{
            if(!response.ok) throw new Response(response.statusText, {status:response.status});
            return response.json();
        })
        .then(data=>{
            setWSInfo({
                links:data.result.links,
                about:data.result.about
            })
        });
    },[refresh]);

    return(
        <Grid container spacing={2}>
            <EditLinksModal
                links={wsInfo.links}
                open={openLinksModal} onClose={()=>setOpenLinksModal(false)} onEdit={()=>setRefresh(n=>n+1)}
            />
            <EditAboutModal
                about={wsInfo.about}
                open={openAboutModal} onClose={()=>setOpenAboutModal(false)} onEdit={()=>setRefresh(n=>n+1)}
            />
            <Grid xs={12} sm={6}>
                <Stack direction='row' justifyContent='center' spacing={'4px'}>
                    <Typography textAlign={'center'} variant='subtitle2'>Links</Typography>  
                    {user && user.permissions.includes('admin') ? 
                        <IconButton size='small' sx={{height:'1lh', width:'1lh'}} onClick={()=>setOpenLinksModal(true)}><Edit fontSize='inherit'/></IconButton> 
                    : null}
                </Stack>
                {wsInfo.links.map((link,i)=>{
                    return (
                        <ReactLink key={i} underline="none" href={link.path} textAlign={'center'} variant='body2' color='text.secondary'
                            sx={{
                                display:'block',
                                ':hover':{
                                    color:'text.primary'
                                }
                            }}
                        >
                            {link.label}
                        </ReactLink>
                    );
                })}
            </Grid>
            <Grid xs={12} sm={6}>
                <Stack direction='row' justifyContent='center' spacing={'4px'}>
                    <Typography textAlign={'center'} variant='subtitle2'>About</Typography>
                    {user && user.permissions.includes('admin') ? 
                        <IconButton size='small' sx={{height:'1lh', width:'1lh'}} onClick={()=>setOpenAboutModal(true)}><Edit fontSize='inherit'/></IconButton>
                    : null}
                </Stack>
                <Typography variant='body2' textAlign={'center'} color='text.secondary'  whiteSpace='break-spaces'>
                    {wsInfo.about}
                </Typography>
            </Grid>
        </Grid>
    )
}

const EditLinksModal = ({links:linksProp, onEdit, open, onClose})=>{
    const [linkForm, setLinkForm] = React.useState({
        label:'',
        path:''
    });
    const [links, setLinks] = React.useState([...linksProp]);

    React.useEffect(()=>{
        if(open) setLinks([...linksProp]);
    },[linksProp,open]);

    const handleAddLink=(event)=>{
        event.preventDefault();
        setLinks(n=>{
            n.push(linkForm);
            return [...n];
        });
        setLinkForm({
            label:'', path:''
        });
    }

    return(
        <SubmitModal
            maxWidth={'xs'}
            onCloseModal={onClose}
            modalOpen={open}
            title='Edit Links'
            submitMethod={'put'}
            submitPath='/api/website/links'
            submitLabel='Save'
            succSubmitMessage='Successfully saved links.'
            getSubmitBody={()=>{
                return JSON.stringify({
                    links
                });
            }}
            onSubmit={onEdit}
        >
            <Stack direction='column' spacing={2}>
                {links.map((link,i)=>(
                    <Paper key={i} variant="outlined" sx={{p:1}}>
                        <Stack direction='column' spacing={1}>
                            <Stack direction='row' spacing='4px' alignItems={'center'}>
                                <IconButton sx={{width:'0.75lh', height:'0.75lh'}} 
                                    onClick={()=>{
                                        setLinks(n=>{
                                            n.splice(i,1);
                                            return [...n];
                                        })
                                    }}
                                >
                                    <Close fontSize='inherit'/>
                                </IconButton>
                                <Typography>Link {i+1}</Typography>
                            </Stack>
                            <TextField fullWidth size='small' label='Label' value={link.label} 
                                onChange={(e)=>{
                                    setLinks(n=>{
                                        n[i].label = e.target.value;
                                        return [...n];
                                    })
                                }}
                            />
                            <TextField fullWidth size='small' label='Path' value={link.path} 
                                onChange={(e)=>{
                                    setLinks(n=>{
                                        n[i].path = e.target.value;
                                        return [...n];
                                    })
                                }}
                            />
                        </Stack>
                    </Paper>
                ))}
            </Stack>
            <Paper variant="outlined" sx={{p:1, mt:2}} >
                <Stack direction='column' spacing={1}>
                    <TextField fullWidth size='small' label='Label' value={linkForm.label} onChange={(e)=>setLinkForm(n=>({...n,label:e.target.value}))} />
                    <TextField fullWidth size='small' label='Path' value={linkForm.path} onChange={(e)=>setLinkForm(n=>({...n,path:e.target.value}))} />
                    <Button variant='contained' disabled={!linkForm.label || !linkForm.path} onClick={handleAddLink}>
                        Add Link
                    </Button>
                </Stack>
            </Paper>
        </SubmitModal>
    );
}

const EditAboutModal = ({about:aboutProp, onEdit, open, onClose})=>{
    const [about, setAbout] = React.useState(aboutProp);
    React.useEffect(()=>{
        setAbout(aboutProp);
    },[aboutProp]);
    return(
        <SubmitModal
            maxWidth='xs'
            modalOpen={open}
            onCloseModal={onClose}
            title='Edit About'
            submitMethod={'put'}
            submitPath='/api/website/about'
            submitLabel='Save'
            succSubmitMessage='Successfully saved about.'
            getSubmitBody={()=>{
                return JSON.stringify({
                    about
                });
            }}
            onSubmit={onEdit}
        >
            <TextField fullWidth multiline minRows={4} label='About' value={about} onChange={(e)=>setAbout(e.target.value)}/>
        </SubmitModal>
    );

}

export default Footer;