import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import PageLink from "../PageLink";

const defaultItems = [];
const ItemsDetailsModal =({items=defaultItems})=>{
    const [open, setOpen] = React.useState(false);

    React.useEffect(()=>{
        if(items !== defaultItems && items!==null)
            setOpen(true);
    },[items]);

    return (
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            fullWidth maxWidth={'sm'}
        >
            <DialogTitle >
                Order Items
            </DialogTitle>
            <DialogContent dividers sx={{p:0}}>
                <TableContainer>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    SKU/Item Name
                                </TableCell>
                                <TableCell>
                                    Item Type
                                </TableCell>
                                <TableCell>
                                    Item Lot
                                </TableCell>
                                <TableCell>
                                    Sell Price
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                items ? 
                                    items.map((item)=>{
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell>{
                                                    item.inventoryItem ? 
                                                        <PageLink path={'/items/edit/'+item.id}>{item.inventoryItem.itemSKU}</PageLink>
                                                    : item.customItem?.name
                                                }</TableCell>
                                                <TableCell>
                                                    {item.inventoryItem?.itemType ?
                                                        <PageLink path={'/items/types/'+item.inventoryItem.itemType.id}>
                                                            {item.inventoryItem.itemType.name}
                                                        </PageLink>
                                                    : ''}
                                                </TableCell>
                                                <TableCell>
                                                    {item.inventoryItem?.itemLot ?
                                                        <PageLink path={'/items/lots/'+item.inventoryItem.itemLot.id}>
                                                            {item.inventoryItem.itemLot.name}
                                                        </PageLink>
                                                    : ''}
                                                </TableCell>
                                                <TableCell>{item.sellPrice}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ItemsDetailsModal;