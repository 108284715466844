import { Alert, Button, IconButton, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { Close, PhotoCamera } from "@mui/icons-material";
import React from "react";

const AnnouncementForm = ({formData, onChangeImg, onChangeForm})=>{
    const [errorOpen, setErrorOpen] = React.useState(false);

    const handleCapture = (event)=>{
        const targetFile = event.target.files[0];
        if(targetFile.size / 1024 ** 2 > 4){
            setErrorOpen(true);
            return;
        }

        onChangeImg(targetFile);
        onChangeForm(n=>({...n, imgName:targetFile.name}));
    }

    return(
        <Stack direction='column' spacing={2}>
            <Snackbar anchorOrigin={{vertical:'top', horizontal:'center' }} open={errorOpen} autoHideDuration={3000} onClose={()=>{setErrorOpen(false)}}>
                <Alert onClose={()=>{setErrorOpen(false)}} severity="error" sx={{ width: '100%' }}>
                    File size exceeded 4MB!
                </Alert>
            </Snackbar>
            <TextField fullWidth label='Title' required value={formData.title} onChange={(event)=>onChangeForm(n=>({...n, title:event.target.value}))}/>
            <TextField fullWidth label='Text' required value={formData.text} multiline minRows={4} onChange={(event)=>onChangeForm(n=>({...n, text:event.target.value}))}/>
            <input
                accept="image/png, image/jpeg"
                hidden
                id="icon-button-photo"
                onChange={handleCapture}
                type="file"
            />
            <Stack direction='row' spacing={2}>
                <label htmlFor="icon-button-photo">
                    <Button variant='contained' startIcon={<PhotoCamera/>} component='span'>
                        Choose Image
                    </Button>
                </label>
            </Stack>
            {formData.imgName ? 
                <Stack direction='row' alignItems='center'>
                    <IconButton 
                        onClick={()=>{
                            onChangeImg(null); 
                            onChangeForm(n=>({...n,imgName:null}));
                        }}
                    >
                        <Close/>
                    </IconButton>
                    <Typography sx={{wordBreak:'break-word'}}>Selected Image: {formData.imgName}</Typography> 
                </Stack>
            : null }
        </Stack>
    );
}

export default AnnouncementForm;