import React from "react";
import Typography from '@mui/material/Typography';

import RouteData from "../RouteData";
import PersonIcon from '@mui/icons-material/Person';
import {FetchersContext} from '../../Contexts/FetchersContext';
import { Navigate } from "react-router-dom";
import Page from "../../Components/Page";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import moment from "moment";
import { UserContext } from "../../Contexts/UserContext";
import { alpha } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
//import ProfileDropTable from "../../Components/Users/ProfileDropTable";

const Profile = ()=>{
    const {user:clientUser} = React.useContext(UserContext);
    const {fetchAuthed, fetchCSRF} = React.useContext(FetchersContext);
    const [refresh, setRefresh] = React.useState(0);
    const [user, setUser] = React.useState({
        name:'',
        username:'',
        createdAt:'',
        vendorCode:''
    });
    const [form, setForm] = React.useState({
        oldPassword:'',
        password:'',
        password2:'',
        name:'',
    });
    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        setLoading(true);
        fetchAuthed({
            path:'/api/users/profile'
        },{
            succ:(data)=>{
                setUser({
                    name:data.result.name,
                    username:data.result.username,
                    createdAt:moment(data.result.createdAt).format('LL'),
                    vendorCode:data.result.vendorCode
                });
            },
            final:()=>{
                setLoading(false);
            }
        });
    },[refresh, fetchAuthed]);

    const generateHandleSubmit = (mode)=>{
        return (e)=>{
            e.preventDefault();
            fetchCSRF({
                succ:(csrf)=>{
                    fetchAuthed({
                        path:'/api/users/profile',
                        method:'put',
                        headers:{
                            'content-type':'application/json',
                            'x-csrf-token':csrf
                        },
                        body:JSON.stringify({
                            password: mode==='password' ? form.password : undefined,
                            oldPassword: mode === 'password' ? form.oldPassword : undefined,
                            name: mode === 'name' ? form.name : undefined,
                        })
                    },{
                        succ:(data)=>{
                            setRefresh(n=>n+1);
                            setForm({
                                name:'',
                                oldPassword:'',
                                password:'',
                                password2:'',
                            });
                        },
                        succMessage:'Successfully changed '+mode
                    });
                }
            });
        }
    }

    /*const mOrderTable = React.useMemo(()=>(
        <ProfileDropTable
            dataPath={'/api/users/drops'}
        />
    ),[]);*/

    return (
        clientUser ? 
        <Page>
            {loading ? (
                <Box sx={{ 
                    position: 'absolute', 
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0 ,
                    display: 'flex',
                    bgcolor:alpha('#000', 0.05),
                    justifyContent: 'center',
                    flexDirection: "column",
                    zIndex:2000
                }}>
                    <CircularProgress sx={{mx:'auto'}}/>
                </Box>
            ):''}
            <Box sx={{p:2}}>
                <Typography variant="h6" gutterBottom>
                    User Profile
                </Typography>
                <Box sx={{px:2,mb:2}}>
                    <Grid container>
                        <Grid xs={12} sm={6}>
                            <Typography><b>Username:</b> {user.username}</Typography>
                            <Typography><b>Name:</b> {user.name}</Typography>
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <Typography><b>Vendor Code:</b> {user.vendorCode}</Typography>
                            <Typography><b>Account Created On:</b> {user.createdAt}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Change Name
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} component='form' onSubmit={generateHandleSubmit('name')}>
                                <Grid xs={12} sm={8} md={10}>
                                    <TextField
                                        label='Name'
                                        size='small'
                                        fullWidth
                                        required
                                        value={form.name}
                                        onChange={(e)=>{
                                            setForm(n=>({
                                                ...n,
                                                name:e.target.value
                                            }));
                                        }}
                                    />
                                </Grid>
                                <Grid xs sm md>
                                    <Button type='submit' fullWidth variant='contained'>
                                        Change
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            Change Password
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} component='form' onSubmit={generateHandleSubmit('password')}>
                                <Grid xs={12} sm={8} md={10}>
                                    <Stack direction='column' spacing={2}>
                                        <TextField
                                            label='Old Password'
                                            size='small'
                                            fullWidth
                                            required
                                            value={form.oldPassword}
                                            onChange={(e)=>{
                                                setForm(n=>({
                                                    ...n,
                                                    oldPassword:e.target.value
                                                }));
                                            }}
                                            type='password'
                                        />
                                        <TextField
                                            label='New Password'
                                            size='small'
                                            fullWidth
                                            required
                                            value={form.password}
                                            onChange={(e)=>{
                                                setForm(n=>({
                                                    ...n,
                                                    password:e.target.value
                                                }));
                                            }}
                                            type='password'
                                        />
                                        <TextField
                                            label='Input New Password Again'
                                            size='small'
                                            fullWidth
                                            required
                                            error={form.password !== form.password2}
                                            helperText={form.password === form.password2 ? '':'Passwords do not match'}
                                            value={form.password2}
                                            onChange={(e)=>{
                                                setForm(n=>({
                                                    ...n,
                                                    password2:e.target.value
                                                }));
                                            }}
                                            type='password'
                                        />
                                    </Stack>
                                </Grid>
                                <Grid xs sm md>
                                    <Button type='submit' fullWidth variant='contained'
                                        disabled={!form.oldPassword || !form.password || form.password !== form.password2}
                                    >
                                        Change
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
            {/*<Box sx={{mt:2}}>
                <Typography variant='h6' align="center">Dropped Items</Typography>
                {mOrderTable}
            </Box>*/}
        </Page>
        : 
        <Navigate to='/signin'/>
    );
}
export const routeData = new RouteData({
    path:'/profile',
    element: <Profile/>
},{
    name: 'Profile',
    icon: <PersonIcon/>,
    isVisible: (user)=>{
        return user;
    }
})