import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

const emptyJSON = {};
const Announcement = ({ announcementData=emptyJSON, onRemove, onEdit})=>{
    const [optionsAnchor, setOptionsAnchor] = React.useState(null);
    const optionsOpened = Boolean(optionsAnchor);

    return(
        <Paper sx={{height:{
            xs:'150px',
            sm:'175px',
            md:'200px'
        }, }}>
            <Grid container sx={{height:'100%'}}>
                <Grid xs={4} md={3} sx={{height:'100%'}}>
                    <Box sx={{
                        backgroundColor:'whitesmoke',
                        height:'100%', 
                        borderRadius:'4px 0 0 4px',
                        position:'relative'
                    }}>
                        <Link style={{
                                borderRadius:'inherit',
                        }} to={'/announcements/'+announcementData.id}>
                            {announcementData.imgName ? 
                                <img style={{
                                    display:'block',
                                    width:'100%',
                                    height:'100%',
                                    objectFit:'cover',
                                    borderRadius:'inherit',
                                }} alt={announcementData.title} src={'/api/announcements/image/'+announcementData.imgName}/>
                            : null }
                            <Box sx={{
                                position:'absolute',
                                top:0,
                                left:0,
                                right:0,
                                bottom:0,
                                opacity:0,
                                borderRadius:'inherit',
                                backgroundColor:'black',
                                ':hover':{
                                    opacity:0.1,
                                }
                            }}>
                                
                            </Box>
                        </Link>
                    </Box>
                </Grid>
                <Grid xs sm sx={{p:1,ml:1,height:'100%'}}>
                    <Box sx={{display:'flex', flexDirection:'column', height:'100%'}}>
                        <Box>
                            <Typography variant='h6' sx={{width:'100%', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>
                                {announcementData.title}
                            </Typography>
                        </Box>
                        <Box sx={{
                                flex:1,
                                my:1,
                                overflow:'hidden',
                                position:'relative',
                                ":after":{
                                    content: '""',
                                    position: 'absolute',
                                    top:0,
                                    left:0,
                                    bottom: 0,
                                    right: 0,
                                    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1))'
                                }
                            }}>
                            <Typography variant='body2' sx={{
                                whiteSpace:'pre-wrap',
                            }}>
                                {announcementData.text}
                            </Typography>
                        </Box>
                        <Box sx={{minHeight:20, display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                            <Typography variant='body2' >
                                {moment(announcementData.dateCreated).format('ll')}
                            </Typography>
                            <Box>
                                <Box sx={{display:{
                                    xs:'none',
                                    sm:'block'
                                }}}>
                                    <Button size='small' onClick={onEdit}>
                                        Edit
                                    </Button>
                                    <Button size='small' onClick={onRemove}>
                                        Remove
                                    </Button>
                                </Box>
                                <Box sx={{display:{
                                    xs:'block',
                                    sm:'none',
                                }}}>
                                    <IconButton variant='small' onClick={(event)=>setOptionsAnchor(event.currentTarget)}>
                                        <MoreHorizIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Menu
                            anchorEl={optionsAnchor}
                            open = {optionsOpened}
                            onClose = {()=>setOptionsAnchor(null)}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={onEdit}>Edit</MenuItem>
                            <MenuItem onClick={onRemove}>Remove</MenuItem>
                        </Menu>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default Announcement;